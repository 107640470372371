/* eslint-disable react-hooks/exhaustive-deps */
import CreateConsumerProject from "../../Projects/CreateProject";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { projectsValidations } from "../../../validation/projectsValidations";
import MainLayout from "../../../components/layout/MainLayout";
import { fileToBase64 } from "../../../utils/fileUtils";
import adminProjectService from "../../../services/adminProjectService";
import { setAdminDashboardTab } from "../../../state/reducers/applicationSlice";
import { useDispatch } from "react-redux";
const Projects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    control,
    watch,
    setError,
    getValues,
    clearErrors,
    setValue,
    trigger,
    resetField,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    defaultValues: {
      grossFloorAreaRadio: "1",
      availableRoofAreaForSolarSelect: "2",
      projectImagesRadio: "0",
      rooftopAreaUnit: { label: "ft²", value: 2 },
      projectModel: [],
    },
    mode: "onChange",
    resolver: yupResolver(projectsValidations),
  });
  const [outerPolygons, setOuterPolygons] = useState([]);
  const [innerPolygons, setInnerPolygons] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    projectTypes: [],
    projectImages: [],
  });
  const projectImagesRadio = watch("projectImagesRadio");
  const location = useLocation();
  const projectData = location?.state?.projectDetails;
  const onChangeProjectImagesRadio = () => {
    state?.projectImages?.forEach((val, key) => {
      if (key === Number(projectImagesRadio)) {
        val.is_thumbnail = true;
      } else {
        val.is_thumbnail = false;
      }
    });
  };
  const resetFormField = (name) => {
    if (isSubmitted) {
      trigger(name);
    }
  };
  const addDocumentToProject = async (data, documentKey, fileKey) => {
    if (data?.[documentKey]?.length > 0 && data?.[fileKey]) {
      try {
        const base64String = await fileToBase64(data?.[fileKey]);
        return {
          attachment: base64String,
          attachment_title: data?.[documentKey],
          extension: data?.[fileKey]?.type?.split("/")[1],
        };
      } catch (error) {
        console.error("Error converting file to base64", error);
      }
    }
    return null;
  };
  const prepareProjectDocuments = async (data) => {
    const documentKeys = ["document1", "document2", "document3"];
    const fileKeys = ["document1File", "document2File", "document3File"];

    const promises = documentKeys.map(async (documentKey, index) => {
      return addDocumentToProject(data, documentKey, fileKeys[index]);
    });

    return Promise.all(promises);
  };
  const submitAddProject = async (payload) => {
    try {
      let response = await adminProjectService.createProject(payload);
      if (response?.data?.status) {
        navigate("/admin/dashboard");
        dispatch(setAdminDashboardTab(1));
        toast.success("Project Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.log("Add Project Error", error);
    }
  };
  const submitEditProject = async (payload) => {
    try {
      let response = await adminProjectService.editProject(payload);
      if (response?.data?.status) {
        navigate("/admin/dashboard");
        toast.success("Project Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.log("Edit Project Error", error);
    }
  };
  const handleFormSubmit = async (data) => {
    let projectDocuments = await prepareProjectDocuments(data);
    if (location?.pathname === "/admin/edit-project") {
      let payload = {
        consumer_id: location?.state?.consumerId,
        solar_project_id: projectData?.id,
        project_name: data?.projectTitle,
        master_project_category_id: 1,
        master_property_type_id: data?.propertyType?.value,
        location: data?.location,
        latitude: state?.center?.lat,
        longitude: state?.center?.lng,
        master_country_id: countryCode,
        rooftop_area: data?.rooftopArea,
        master_roof_area_unit_id: data?.rooftopAreaUnit?.value,
        project_images: state?.projectImages,
        project_documents: projectDocuments.filter((doc) => doc !== null),
        inner_coords: state?.innerCoords,
        outer_coords: state?.outerCoords,
        project_models: data?.projectModel,
      };
      submitEditProject(payload);
    } else {
      let payload = {
        consumer_id: location?.state?.consumerId,
        project_name: data?.projectTitle,
        master_project_category_id: 1,
        master_property_type_id: data?.propertyType?.value,
        location: data?.location,
        latitude: state?.center?.lat,
        longitude: state?.center?.lng,
        master_country_id: countryCode,
        rooftop_area: data?.rooftopArea,
        master_roof_area_unit_id: data?.rooftopAreaUnit?.value,
        project_images: state?.projectImages,
        project_documents: projectDocuments.filter((doc) => doc !== null),
        inner_coords: state?.innerCoords,
        outer_coords: state?.outerCoords,
        project_models: data?.projectModel?.sort(),
      };
      submitAddProject(payload);
    }
  };
  const autoFillData = (data) => {
    setState({ addressLatLng: { lat: data?.latitude, lng: data?.longitude } });
    setCountryCode(data?.master_country_id);
    const setValueIfExists = (key, value) => {
      if (value) {
        setValue(key, value);
      }
    };
    const setValueIfNotEmpty = (key, value) => {
      if (value?.label) {
        setValue(key, value);
      }
    };
    const {
      project_name,
      projectImages,
      projectDocuments,
      MasterPropertyType,
      location,
      latitude,
      longitude,
      outer_coords,
      inner_coords,
      MasterRoofAreaUnit,
      SolarProjectModels,
    } = data || {};
    let projectModels = [];
    SolarProjectModels.forEach((val) => {
      projectModels?.push(String(val?.MasterProjectModel?.id));
    });
    projectImages.forEach((val, index) => {
      if (val?.is_thumbnail) {
        setValueIfExists("projectImagesRadio", `${index}`);
      }
    });
    setState({ projectImages: [...projectImages], center: { lat: latitude, lng: longitude } });
    projectDocuments?.forEach((doc, index) => {
      const docNumber = index + 1;
      setValueIfExists(`document${docNumber}`, doc.attachment_title);
      setValueIfExists(`document${docNumber}File`, doc.attachment);
    });
    setValueIfExists("projectTitle", project_name);
    setValueIfExists("projectModel", projectModels);
    setValueIfNotEmpty("propertyType", {
      label: MasterPropertyType?.name,
      value: MasterPropertyType?.id,
    });
    setValueIfExists("location", location);
    setOuterPolygons(outer_coords);
    setInnerPolygons(inner_coords);
    setValueIfNotEmpty("rooftopAreaUnit", {
      label: MasterRoofAreaUnit?.name,
      value: MasterRoofAreaUnit?.id,
    });
  };
  useEffect(() => {
    if (state?.projectImages?.length > 0) {
      onChangeProjectImagesRadio();
    }
  }, [projectImagesRadio]);

  useEffect(() => {
    setValue("projectImages", state?.projectImages?.length);
  }, [state?.projectImages]);

  useEffect(() => {
    if (projectData) {
      autoFillData(projectData);
    }
  }, []);
  useEffect(() => {
    if (!location?.state?.consumerId) {
      navigate("/admin/dashboard");
    }
  }, []);
  return (
    <MainLayout
      className="px-4 xl:px-24 2xl:px-48"
      changeRequest={location?.state?.projectDetails?.MasterProjectSubStatus?.id === 7 ? location?.state?.projectDetails : null}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col">
          <CreateConsumerProject
            setState={setState}
            control={control}
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            state={state}
            setError={setError}
            clearErrors={clearErrors}
            resetFormField={resetFormField}
            resetField={resetField}
            setCountryCode={setCountryCode}
            outerPolygons={outerPolygons}
            innerPolygons={innerPolygons}
            setOuterPolygons={setOuterPolygons}
            setInnerPolygons={setInnerPolygons}
            trigger={trigger}
            getValues={getValues}
          />
        </div>
      </form>
    </MainLayout>
  );
};

export default Projects;
