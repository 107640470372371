import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, orderBy, limit, onSnapshot, addDoc, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";

let authDomain = "";
let projectId = "";
let storageBucket = "";
let messagingSenderId = "";
let appId = "";

if (process.env.REACT_APP_CURRENT_INSTANCE === "DEV") {
  authDomain = "gaed-chat.firebaseapp.com";
  projectId = "gaed-chat";
  storageBucket = "gaed-chat.appspot.com";
  messagingSenderId = "726071191849";
  appId = "1:726071191849:web:cca1f6b10a8cb6770a6f38";
} else if (process.env.REACT_APP_CURRENT_INSTANCE === "QA") {
  authDomain = "gaed-qa.firebaseapp.com";
  projectId = "gaed-qa";
  storageBucket = "gaed-qa.appspot.com";
  messagingSenderId = "1097636623341";
  appId = "1:1097636623341:web:8dfa86f16aa01285b0ede4";
} else if (process.env.REACT_APP_CURRENT_INSTANCE === "UAT") {
  authDomain = "gaed-uat.firebaseapp.com";
  projectId = "gaed-uat";
  storageBucket = "gaed-uat.appspot.com";
  messagingSenderId = "47793842959";
  appId = "1:47793842959:web:d0b4a3f4ed7bdb0a76ac7a";
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export { collection, query, orderBy, limit, onSnapshot, addDoc, serverTimestamp };
