import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const propertyService = {
  addProperty: (data) => {
    return http.post(URLS.property.addProperty, data);
  },
  uploadPropertyMedia: (data) => {
    return http.post(URLS.property.uploadPropertyMedia, data);
  },
  saveAsDraftProperty: (data) => {
    return http.post(URLS.property.saveAsDraftProperty, data);
  },
  getPropertyList: () => {
    return http.get(URLS.property.getPropertyList);
  },
  getPropertyDetails: (data) => {
    return http.post(URLS.property.getPropertyDetails, data);
  },
  downloadPropertyMedia: (url) => {
    return http.get(url);
  },
  editProperty: (data) => {
    return http.post(URLS.property.editProperty, data);
  },
  editPropertyMedia: (data) => {
    return http.post(URLS.property.editPropertyMedia, data);
  },
};

export default propertyService;
