import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const authService = {
  login: (data) => {
    return http.post(URLS.auth.login, data);
  },
  signup: (data) => {
    return http.post(URLS.auth.signup, data);
  },
  sendotp: (data) => {
    return http.post(URLS.auth.sendotp, data);
  },
  verifyotp: (data) => {
    return http.post(URLS.auth.verifyotp, data);
  },
  emailCheck: (data) => {
    return http.post(URLS.auth.emailCheck, data);
  },
  consumerKyc: (data) => {
    return http.post(URLS.auth.consumerKyc, data);
  },
  refreshToken: (data) => {
    return http.post(URLS.auth.refreshToken, data);
  },
  getConsumerDetails: () => {
    return http.get(URLS.auth.getConsumerDetails);
  },
  consumerKycV1: (data) => {
    return http.post(URLS.auth.consumerKycV1, data);
  },
  hideProfileDetails: (data) => {
    return http.post(URLS.auth.hideProfileDetails, data);
  },
};

export default authService;
