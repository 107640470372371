import * as Yup from "yup";

const projectTitle = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 characters.")
  .max(100, "Field cannot be more than 100 characters.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const propertyType = Yup.object().required("Field is Required.");

const projectModel = Yup.array()
  .min(1, "Field is Required.")
  .required("Field is Required.")
  .test("length", "Field is Required.", (val) => {
    return val?.length > 0;
  });

const location = Yup.string().required("Field is Required.");

const rooftopArea = Yup.string().required("Field is Required.");

const projectImages = Yup.number().min(2, "Please select atleast 2 images.");

const pitch = Yup.string().required("Field is Required.");

const totalProjectCost = Yup.string().required("Field is Required.").max(10, "Field cannot be more than 10 digits.");

const tentativeTimeline = Yup.string().required("Field is Required.").max(2, "Field cannot be more than 2 digits.");

const capexCommercialProposal = Yup.mixed().required("Field is Required.");

const capexTechnicalProposal = Yup.mixed().required("Field is Required.");

const ppaCommercialProposal = Yup.mixed().required("Field is Required.");

const ppaTechnicalProposal = Yup.mixed().required("Field is Required.");

const leasingCommercialProposal = Yup.mixed().required("Field is Required.");

const leasingTechnicalProposal = Yup.mixed().required("Field is Required.");

const costPerMonth = Yup.string().required("Field is Required.").max(10, "Field cannot be more than 10 digits.");

const totalMonths = Yup.string().required("Field is Required.").max(2, "Field cannot be more than 2 digits.");

const tariffRate = Yup.string().required("Field is Required.").max(10, "Field cannot be more than 10 digits.");

export const projectsValidations = Yup.object().shape({ projectTitle, propertyType, location, rooftopArea, projectImages, projectModel });

export const placeBid = Yup.object().shape({ pitch, tentativeTimeline });

export const capexSchema = Yup.object().shape({ totalProjectCost, capexCommercialProposal, capexTechnicalProposal, tentativeTimeline, pitch });

export const ppaSchema = Yup.object().shape({ tariffRate, ppaCommercialProposal, ppaTechnicalProposal, tentativeTimeline, pitch });

export const leasingSchema = Yup.object().shape({
  costPerMonth,
  totalMonths,
  leasingCommercialProposal,
  leasingTechnicalProposal,
  tentativeTimeline,
  pitch,
});

export const cpl = placeBid.shape({
  totalProjectCost,
  capexCommercialProposal,
  capexTechnicalProposal,
  tariffRate,
  ppaCommercialProposal,
  ppaTechnicalProposal,
  costPerMonth,
  totalMonths,
  leasingCommercialProposal,
  leasingTechnicalProposal,
  tentativeTimeline,
  pitch,
});

export const cp = placeBid.shape({
  totalProjectCost,
  capexCommercialProposal,
  capexTechnicalProposal,
  tariffRate,
  ppaCommercialProposal,
  ppaTechnicalProposal,
  tentativeTimeline,
  pitch,
});

export const cl = placeBid.shape({
  totalProjectCost,
  capexCommercialProposal,
  capexTechnicalProposal,
  costPerMonth,
  totalMonths,
  leasingCommercialProposal,
  leasingTechnicalProposal,
  tentativeTimeline,
  pitch,
});

export const pl = placeBid.shape({
  tariffRate,
  ppaCommercialProposal,
  ppaTechnicalProposal,
  costPerMonth,
  totalMonths,
  leasingCommercialProposal,
  leasingTechnicalProposal,
  tentativeTimeline,
  pitch,
});
