import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const vendorKycService = {
  addVendorKyc: (data) => {
    return http.post(URLS.vendorKyc.addVendorKyc, data);
  },
  getVendorKycDetails: () => {
    return http.get(URLS.vendorKyc.getVendorKycDetails);
  },
  getVendorKycStatus: () => {
    return http.get(URLS.vendorKyc.getVendorKycStatus);
  },
  hideProfileDetails: (data) => {
    return http.post(URLS.vendorKyc.hideProfileDetails, data);
  },
};

export default vendorKycService;
