/* eslint-disable react-hooks/exhaustive-deps */
import { TfiClose } from "react-icons/tfi";
import CustomFileSelect from "./CustomFileSelect";
import CustomInput from "./CustomInput";
import { useEffect } from "react";

const CustomFileInput = ({
  register,
  errors,
  inputName,
  fileName,
  watch,
  setValue,
  resetField,
  resetFormField,
  label,
  size,
  setError,
  clearErrors,
  fileTypes,
  required,
}) => {
  const inputValue = watch(inputName);
  const fileValue = watch(fileName);

  useEffect(() => {
    if (inputValue?.length > 0 && !fileValue) {
      setError(fileName, { type: "server", message: "Please select a file." });
    } else if (!!fileValue && inputValue?.length === 0) {
      setError(fileName, { type: "server", message: "Please input a file name." });
    } else {
      clearErrors(fileName);
    }
  }, [inputValue]);
  useEffect(() => {
    if (!!fileValue && inputValue?.length === 0) {
      setError(inputName, { type: "server", message: "Please input a file name." });
    } else if (!fileValue && inputValue?.length > 0) {
      setError(fileName, { type: "server", message: "Please select a file." });
    } else {
      clearErrors(inputName);
    }
  }, [fileValue]);
  return (
    <div className="rounded-2xl p-4 border border-gray-500">
      <div className="flex flex-wrap">
        <div className="w-full md:w-3/5">
          <CustomInput
            required={required}
            name={inputName}
            label={label}
            register={register}
            setValue={setValue}
            watch={watch}
            errors={errors}
            placeholder="Add document title"
          />
          {!errors?.[inputName]?.message && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[fileName]?.message}</span>}
        </div>
        <div className="w-full md:w-2/5 md:pl-2 xs:pl-0">
          <CustomFileSelect name={fileName} size={size} fileTypes={fileTypes} setError={setError} setValue={setValue} clearErrors={clearErrors} />
        </div>
      </div>
      <div className="flex flex-wrap mt-2">
        {watch(fileName) && (
          <div className="w-full border-b py-2 flex items-center">
            <span className=" truncate max-w-[90%] text-sm font-normal text-black-900">{watch(fileName)?.name}</span>
            <TfiClose
              className="w-3 h-3 ml-3 cursor-pointer"
              onClick={() => {
                setValue(fileName, null);
                resetField(fileName);
                resetFormField(fileName);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFileInput;
