import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const buySell = {
  getMarketplaceList: (data) => {
    return http.post(URLS.buySell.getMarketplaceList, data);
  },
  getProjectList: (data) => {
    return http.post(URLS.buySell.getProjectList, data);
  },
  addExistingSolar: (data) => {
    return http.post(URLS.buySell.addExistingSolar, data);
  },
  saveAsDraftExistingSolar: (data) => {
    return http.post(URLS.buySell.saveAsDraftExistingSolar, data);
  },
  editExistingSolar: (data) => {
    return http.post(URLS.buySell.editExistingSolar, data);
  },
  getProjectDetails: (data) => {
    return http.post(URLS.buySell.getProjectDetails, data);
  },
  extendBidValidity: (data) => {
    return http.post(URLS.buySell.extendBidValidity, data);
  },
  bidList: (data) => {
    return http.post(URLS.buySell.bidList, data);
  },
  highestBid: (data) => {
    return http.post(URLS.buySell.highestBid, data);
  },
  addBid: (data) => {
    return http.post(URLS.buySell.addBid, data);
  },
  viewBid: (data) => {
    return http.post(URLS.buySell.viewBid, data);
  },
  projectBidList: (data) => {
    return http.post(URLS.buySell.projectBidList, data);
  },
  acceptRejectBid: (data) => {
    return http.post(URLS.buySell.acceptRejectBid, data);
  },
  raiseBid: (data) => {
    return http.post(URLS.buySell.raiseBid, data);
  },
  addFaq: (data) => {
    return http.post(URLS.buySell.addFaq, data);
  },
  editFaq: (data) => {
    return http.post(URLS.buySell.editFaq, data);
  },
  deleteFaq: (data) => {
    return http.post(URLS.buySell.deleteFaq, data);
  },
  faqList: (data) => {
    return http.post(URLS.buySell.faqList, data);
  },
  getProjectCountryList: () => {
    return http.get(URLS.buySell.getProjectCountryList);
  },
  getVendorCountryList: (data) => {
    return http.post(URLS.buySell.getVendorCountryList, data);
  },
};

export default buySell;
