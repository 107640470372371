const CustomCheckbox2 = ({ errors, name, label, required, options, watch, setValue, clearErrors, trigger, disabled = false }) => {
  let selectedOptions = [...watch(name)];
  const handleChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      selectedOptions.push(...selectedOptions, value);
      selectedOptions = [...new Set(selectedOptions)];
    } else {
      const index = selectedOptions.indexOf(value);
      if (index > -1) {
        selectedOptions.splice(index, 1);
      }
    }
    setValue(name, selectedOptions);
    if (selectedOptions?.length > 0) {
      clearErrors(name);
    } else {
      trigger(name);
    }
  };
  return (
    <div>
      {label && (
        <label className="block tracking-wide text-black-100 text-xs font-normal mb-2">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="w-full">
        {options?.length > 0 && (
          <>
            <div className="w-full flex space-x-8">
              {options?.map((val) => {
                return (
                  <div className="flex space-x-2 items-center">
                    <input
                      disabled={disabled ? (watch(name)?.includes(String(val?.value)) && watch(name)?.length === 1 ? true : false) : false}
                      checked={watch(name)?.includes(String(val?.value))}
                      value={val?.value}
                      type="checkbox"
                      id={`${val?.label}${val?.value}`}
                      className="w-4 h-4 text-green-500 focus:ring-green-500 border-gray-300 rounded accent-[#325B22]"
                      onChange={handleChange}
                    />
                    <label for={`${val?.label}${val?.value}`} className="hover:cursor-pointer text-sm font-normal text-black-100">
                      {val?.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {errors?.[name] && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[name]?.message}</span>}
    </div>
  );
};
export default CustomCheckbox2;
