import { useSelector } from "react-redux";
import AdminChatModule from "../components/AdminChatModule";
const TestChat = () => {
  const storeUserData = useSelector((state) => state?.user?.userData);
  console.log(storeUserData?.userId);

  return (
    <div style={{ height: "100vh" }} className="relative w-full">
      <AdminChatModule
        sendId={storeUserData?.userId}
        recvId="A"
        // recvEmail={projectData?.vendor?.Vendor?.email}
        name={`Admin  `}
        consumerId={storeUserData?.userId}
        // profileImage={projectData?.vendor?.image}
      />
    </div>
  );
};

export default TestChat;
