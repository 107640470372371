import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const adminProjectService = {
  currentProjects: (data) => {
    return http.post(URLS.admin.projects.currentProjects, data);
  },
  projectDetails: (data) => {
    return http.post(URLS.admin.projects.projectDetails, data);
  },
  changeSubStatus: (data) => {
    return http.post(URLS.admin.projects.changeSubStatus, data);
  },
  uploadReport: (data) => {
    return http.post(URLS.admin.projects.uploadReport, data);
  },
  dealRoom: (data) => {
    return http.post(URLS.admin.projects.dealRoom, data);
  },
  removeBid: (data) => {
    return http.post(URLS.admin.projects.removeBid, data);
  },
  executeArchive: (data) => {
    return http.post(URLS.admin.projects.executeArchive, data);
  },
  shortlist: (data) => {
    return http.post(URLS.admin.projects.shortlist, data);
  },
  scheduleSiteVisit: (data) => {
    return http.post(URLS.admin.projects.scheduleSiteVisit, data);
  },
  extendValidity: (data) => {
    return http.post(URLS.admin.projects.extendValidity, data);
  },
  createProject: (data) => {
    return http.post(URLS.admin.projects.createProject, data);
  },
  editProject: (data) => {
    return http.post(URLS.admin.projects.editProject, data);
  },
  acceptRejectProposal: (data) => {
    return http.post(URLS.admin.projects.acceptRejectProposal, data);
  },
};

export default adminProjectService;
