import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Spinner from "../components/Spinner";
import TestChat from "../pages/TestChat";
import Chatbox from "../components/ChatBox";
import CreateProject from "../pages/Admin/Projects/CreateProject";

const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
const Login = lazy(() => import("../pages/Auth/Login/Login"));
const Signup = lazy(() => import("../pages/Auth/Signup"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const MarketplaceList = lazy(() => import("../pages/BuySell/MarketplaceList"));
const BuySell = lazy(() => import("../pages/BuySell"));
const ProjectDetails = lazy(() => import("../pages/BuySell/ProjectDetails"));
const Vendorkyc = lazy(() => import("../pages/VendorKyc"));
const VendorProfile = lazy(() => import("../pages/VendorKyc/VendorProfile"));
const BidProjectDetail = lazy(() => import("../pages/BuySell/BidProjectDetail"));
const MyBids = lazy(() => import("../pages/BuySell/MyBids"));
const BidDetails = lazy(() => import("../pages/BuySell/BidDetails"));
const DealRoom = lazy(() => import("../pages/BuySell/DealRoom"));
const AdminLogin = lazy(() => import("../pages/Admin/Auth/AdminLogin"));
const AdminProjectDetails = lazy(() => import("../pages/Admin/BuySell/ProjectDetails"));
const AdminProjectDetails2 = lazy(() => import("../pages/Admin/Projects/ProjectDetails"));
const Dashboard = lazy(() => import("../pages/Admin/Dashboard/Dashboard"));
const RejectedConsumerKYC = lazy(() => import("../pages/RejectedConsumerKYC"));
const RejectedVendorKYC = lazy(() => import("../pages/RejectedVendorKYC"));
const PendingVendorKYC = lazy(() => import("../pages/PendingVendorKYC"));
const ConsumerProfile = lazy(() => import("../pages/ConsumerProfile"));
const PendingConsumerKYC = lazy(() => import("../pages/PendingConsumerKYC"));

const Projects = lazy(() => import("../pages/Projects"));
const ProjectList = lazy(() => import("../pages/Projects/ProjectList"));
const ProBidProjectDetail = lazy(() => import("../pages/Projects/BidProjectDetail"));
const ProDealRoom = lazy(() => import("../pages/Projects/DealRoom"));
const ProBidDetails = lazy(() => import("../pages/Projects/BidDetails"));
const VendorDealRoom = lazy(() => import("../pages/Projects/VendorDealRoom"));
const VendorBidDetails = lazy(() => import("../pages/Projects/VendorBidDetail"));
const ProjectDetail = lazy(() => import("../pages/Projects/ProjectDetail"));
const ConsumerProjectDetails = lazy(() => import("../pages/Projects/ConsumerProjectDetails"));

const TestMap = lazy(() => import("../pages/TestMap"));

const AppRouter = () => {
  const profile = useSelector((state) => state.application.profile);
  const consumerKycStatusId = useSelector((state) => state?.user?.userData?.consumerKycStatusId);
  const vendorKycStatus = useSelector((state) => state?.user?.userData?.vendorKycStatus);
  const vendorKycStatusId = useSelector((state) => state?.user?.userData?.vendorKycStatusId);
  const userRole = useSelector((state) => state?.user?.userData?.loggedIn);

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/admin/login"
            element={
              <PublicRoute>
                <AdminLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute role="Admin">
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/project-details"
            element={
              <ProtectedRoute role="Admin">
                <AdminProjectDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/project-details2"
            element={
              <ProtectedRoute role="Admin">
                <AdminProjectDetails2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/create-project"
            element={
              <ProtectedRoute role="Admin">
                <CreateProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/edit-project"
            element={
              <ProtectedRoute role="Admin">
                <CreateProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/consumer-profile"
            element={
              <ProtectedRoute role="Customer">
                <ConsumerProfile />
              </ProtectedRoute>
            }
          />
          {consumerKycStatusId === 2 && (
            <>
              {profile && (
                <>
                  {vendorKycStatusId === 1 && (
                    <Route
                      path="/vendor-kyc-status"
                      element={
                        <ProtectedRoute role="Customer">
                          <PendingVendorKYC />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  {vendorKycStatusId === 3 && (
                    <Route
                      path="/vendor-kyc-status"
                      element={
                        <ProtectedRoute role="Customer">
                          <RejectedVendorKYC />
                        </ProtectedRoute>
                      }
                    />
                  )}
                  <Route
                    path="/add-existing-project"
                    element={
                      <ProtectedRoute role="Customer">
                        <BuySell />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/add-new-project"
                    element={
                      <ProtectedRoute role="Customer">
                        <Projects />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="/projects"
                    element={
                      <ProtectedRoute role="Customer">
                        <ProjectList />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path="/edit-new-project"
                    element={
                      <ProtectedRoute role="Customer">
                        <Projects />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/edit-existing-project"
                    element={
                      <ProtectedRoute role="Customer">
                        <BuySell />
                      </ProtectedRoute>
                    }
                  />
                  {!vendorKycStatus && (
                    <Route
                      path="/vendor-kyc"
                      element={
                        <ProtectedRoute role="Customer">
                          <Vendorkyc />
                        </ProtectedRoute>
                      }
                    />
                  )}
                </>
              )}
              {!profile && (
                <>
                  <Route
                    path="/project-details/"
                    element={
                      <ProtectedRoute role="Customer">
                        <ProBidProjectDetail />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/project-details/:id"
                    element={
                      <ProtectedRoute role="Customer">
                        <BidProjectDetail />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/my-bids"
                    element={
                      <ProtectedRoute role="Customer">
                        <MyBids />
                      </ProtectedRoute>
                    }
                  />
                </>
              )}
              {vendorKycStatus && (
                <Route
                  path="/vendor-profile"
                  element={
                    <ProtectedRoute role="Customer">
                      <VendorProfile />
                    </ProtectedRoute>
                  }
                />
              )}
              <Route
                path="/bid-list/:id"
                element={
                  <ProtectedRoute role="Customer">
                    <BidDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/deal-room"
                element={
                  <ProtectedRoute role="Customer">
                    <DealRoom />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects-deal-room"
                element={
                  <ProtectedRoute role="Customer">
                    <ProDealRoom />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects-details"
                element={
                  <ProtectedRoute role="Customer">
                    <ProjectDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consumer-project-details"
                element={
                  <ProtectedRoute role="Customer">
                    <ConsumerProjectDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/vendor-deal-room"
                element={
                  <ProtectedRoute role="Customer">
                    <VendorDealRoom />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/vendor-bid-details"
                element={
                  <ProtectedRoute role="Customer">
                    <VendorBidDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/buy-sell"
                element={
                  <ProtectedRoute role="Customer">
                    <MarketplaceList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects"
                element={
                  <ProtectedRoute role="Customer">
                    <ProjectList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/buy-sell/:id"
                element={
                  <ProtectedRoute role="Customer">
                    <ProjectDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bid-details"
                element={
                  <ProtectedRoute role="Customer">
                    <ProBidDetails />
                  </ProtectedRoute>
                }
              />
            </>
          )}
          {!consumerKycStatusId && <Route path="*" element={<Signup />} />}
          {consumerKycStatusId === 1 && <Route path="*" element={<PendingConsumerKYC />} />}
          {(consumerKycStatusId === 2 || userRole === "Admin") && <Route path="*" element={<PageNotFound />} />}
          {consumerKycStatusId === 3 && <Route path="*" element={<RejectedConsumerKYC />} />}
          <Route path="/test-map" element={<TestMap />} />
          <Route path="/test-chat" element={<TestChat />} />
        </Routes>
      </Suspense>
      {consumerKycStatusId === 2 && <Chatbox />}
    </div>
  );
};

export default AppRouter;
