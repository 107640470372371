import { useTranslation } from "react-i18next";

const CustomFileSelect = ({ name, size, fileTypes, setError, setValue, clearErrors }) => {
  const { t } = useTranslation();
  const cursor = false ? "cursor-not-allowed" : "cursor-pointer";
  const className = `xs:mt-3 md:mt-6 tracking-wide font-medium bg-secondary text-black text-sm w-full py-2 rounded-full hover:bg-yellow-400 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${cursor}`;
  const handleSelectFile = (e) => {
    const selectedImage = e?.target?.files[0];
    if (selectedImage?.name === e.target.files[0]?.name) {
      e.target.value = null;
    }
    if (!fileTypes.includes(selectedImage.type)) {
      setError(name, {
        type: "server",
        message: "Select a valid file.",
      });
      setValue(name, null);
      return;
    }
    if (!selectedImage || selectedImage.size > size * 1024 * 1024) {
      setError(name, {
        type: "server",
        message: `File size must be less than ${size}MB`,
      });
      setValue(name, null);
      return;
    }
    setValue(name, selectedImage);
    clearErrors(name);
  };

  return (
    <div>
      <label htmlFor={name} type="button" className={className}>
        <span>{t("app.signup.step2.button.browse")}</span>
        <input type="file" id={name} className="hidden" onChange={handleSelectFile} />
      </label>
    </div>
  );
};

export default CustomFileSelect;
