import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const adminAuthService = {
  login: (data) => {
    return http.post(URLS.admin.auth.login, data);
  },
  sendotp: (data) => {
    return http.post(URLS.admin.auth.sendotp, data);
  },
  verifyotp: (data) => {
    return http.post(URLS.admin.auth.verifyotp, data);
  },
  refreshToken: (data) => {
    return http.post(URLS.admin.auth.refreshToken, data);
  },
};

export default adminAuthService;
