import { Controller } from "react-hook-form";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";

const CustomInputSelect2 = ({
  width = "auto",
  inputName,
  selectName,
  label,
  required,
  placeholder,
  disabled,
  leftLabel,
  type,
  options,
  defaultValue,
  watch = () => "",
  errors = () => "",
  control = () => "",
  register = () => "",
  setValue = () => "",
  desc = "",
  pos = "bottom",
  delay = 1000,
}) => {
  const baseClass =
    "custom-input-with-select text-sm placeholder:text-sm  placeholder:text-gray-100 appearance-none rounded-full flex w-full text-black-100 border border-gray-300 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed z-0 focus:border-sky-600";
  const errorClass = errors?.[inputName] ? "border-red-600" : "focus:border-primary";
  const onChangeInput = (e) => {
    register(inputName).onChange(e);
    if (type === "number") {
      const sanitizedValue = e.target.value.replace(/\D/g, "");
      setValue(inputName, sanitizedValue);
    } else if (type === "decimal") {
      const sanitizedValue = e.target.value.replace(/(^\d*\.?\d*).*|[^0-9.]/g, "$1");
      setValue(inputName, sanitizedValue);
    }
  };
  return (
    <div>
      <label className="block tracking-wide text-black-100 text-xs font-normal mb-2">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <Tooltip title={desc} placement={pos} enterDelay={delay}>
        <div className={`${baseClass} ${errorClass}`}>
          <input
            className="w-full rounded-full px-3 pr-6 tex-sm text-black-100"
            disabled={disabled ? true : false}
            placeholder={placeholder}
            // required={required}
            {...register(inputName)}
            value={watch(inputName) || ""}
            onChange={onChangeInput}
          />
          <Controller
            name={selectName}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                defaultValue={defaultValue ? defaultValue : options?.length > 0 && options[0]}
                placeholder=""
                isSearchable={false}
                options={options}
                theme={(theme) => ({
                  ...theme,
                  height: 160,
                  colors: { ...theme.colors, primary25: "#f6f6f6", primary: "#325B22" },
                })}
                className="text-sm text-black-100 w-44"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: width,
                    borderTopRightRadius: 100,
                    borderBottomRightRadius: 100,
                    right: 10,
                    borderRight: 0,
                    borderBottom: 0,
                    borderTop: 0,
                    borderWidth: 1,
                    borderRadius: 0,
                    height: 34,
                    minHeight: 34,
                    boxShadow: "none",
                    borderColor: errors?.[inputName] ? "rgb(220 38 38)" : "#c1c1c1",
                    "&:hover": { borderColor: "" },
                    // border:  ? "primary" : "#D9D9D9",
                  }),
                  option: (base) => ({
                    ...base,
                    borderBottom: `1px solid #D9D9D9`,
                    height: "100%",
                  }),
                }}
              />
            )}
          />
        </div>
      </Tooltip>
      {errors?.[inputName] && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[inputName]?.message}</span>}
    </div>
  );
};

export default CustomInputSelect2;
