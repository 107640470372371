import { MdClose } from "react-icons/md";
import HeadingTitle from "./HeadingTitle";
import { useState } from "react";
import { fileToBase64 } from "../utils/fileUtils";

const MediaGallery = ({ required, title, subTitle, state, name, errors, setState, setError, clearErrors, type, control, disabled }) => {
  const [viewImage, setViewImage] = useState(null);
  const handleSelectFile = async (e) => {
    const selectedImage = e?.target?.files[0];
    if (selectedImage?.name === e.target.files[0]?.name) {
      e.target.value = null;
    }
    if (!selectedImage || selectedImage.size > 2 * 1024 * 1024) {
      setError(name, {
        type: "server",
        message: "Image must be less than 2MB",
      });
      return;
    }

    if (!["image/png", "image/jpeg", "image/jpg"].includes(selectedImage.type)) {
      setError(name, {
        type: "server",
        message: "Select a valid image (jpg, jpeg, or png only).",
      });
      return;
    }
    if (type === "base64") {
      try {
        const base64String = await fileToBase64(selectedImage);
        setState({
          [name]: [
            ...state?.[name],
            {
              attachment: base64String,
              attachment_title: selectedImage?.name,
              extension: selectedImage?.type.split("/")[1],
              is_thumbnail: state?.projectImages?.length > 0 ? false : true,
            },
          ],
        });
      } catch (error) {
        console.error("Error converting file to base64", error);
      }
    } else {
      setState({ [name]: [...state?.[name], selectedImage] });
    }
    clearErrors(name);
  };
  const handleClickPreviousImage = (event) => {
    event.stopPropagation();
    setViewImage((prev) => {
      return { image: state?.[name][prev?.key - 1], key: prev?.key - 1 };
    });
  };

  const handleClickNextImage = (event) => {
    event.stopPropagation();
    setViewImage((prev) => {
      return { image: state?.[name][prev?.key + 1], key: prev?.key + 1 };
    });
  };
  const handleCloseImageView = () => {
    setViewImage(null);
  };

  const handleViewImage = (key) => {
    const image = state?.[name]?.find((_, index) => index === key);
    setViewImage({ image, key });
  };

  const handleRemoveImage = (key, event) => {
    event.stopPropagation();
    if (state?.[name][key]?.is_thumbnail && key !== 0) {
      let image = state?.[name]?.filter((_, index) => key !== index);
      image[0] = { ...image[0], is_thumbnail: true };
      setState({ [name]: image });
    } else {
      const image = state?.[name]?.filter((_, index) => key !== index);
      setState({ [name]: image });
    }
  };

  const onChangeThumbnail = (e, index) => {
    const newArr = state?.[name];
    newArr[index] = { ...newArr[index], is_thumbnail: true };
    newArr?.forEach((val, i) => {
      if (i !== index) {
        newArr[i] = { ...newArr[i], is_thumbnail: false };
      }
    });
    setState({ [name]: newArr });
  };
  return (
    <div>
      <div className="mb-6">
        <HeadingTitle required={required} headingtitle={title} subTitle={subTitle} />
        {!disabled && (
          <div className="flex items-center justify-center w-full mb-10">
            <label
              htmlFor="dropzone-file"
              className={`flex flex-col items-center justify-center w-full h-64 border-dashed border-2 border-gray-100 rounded-3xl bg-white hover:bg-gray-50 ${
                state?.[name]?.length >= 4 ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 font-medium xs:text-sm xl:text-base text-black-900 flex justify-center items-center">Click to upload</p>
                <p className="xs:text-xs xl:text-sm text-gray-400"> JPG, JPEG or PNG (MAX. 2 MB)</p>
                <p className="xs:text-xs xl:text-sm text-gray-400 mt-2">You can upload max 4 images</p>
                {errors?.[name] && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[name]?.message}</span>}
              </div>
              <input
                disabled={state?.[name]?.length >= 4}
                id="dropzone-file"
                type="file"
                accept=".png, .jpg, .jpeg"
                className="hidden"
                onChange={handleSelectFile}
              />
            </label>
          </div>
        )}

        {state?.[name]?.length === 0 && (
          <div className="flex flex-1 flex-wrap -m-1 mb-10 space-x-3">
            <div id="empty" className="h-full w-full text-center flex flex-col justify-center items-center">
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="xs:text-sm xl:text-base text-gray-400">No files selected</span>
            </div>
          </div>
        )}
        <div className="grid xs:grid-cols-2 md:grid-cols-4 gap-4 mb-5">
          {state?.[name]?.map((image, key) => (
            <div key={`name${key}`}>
              {!disabled && (
                <div className="pl-[1.5rem]">
                  <input
                    onChange={(e) => onChangeThumbnail(e, key)}
                    name="thumbnail"
                    checked={image?.is_thumbnail}
                    type="radio"
                    className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[0] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  />
                  <label className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm font-normal text-black-100">Set as Thumbnail</label>
                </div>
              )}
              <article
                style={{ borderRadius: "25px !important" }}
                className="group hasImage w-full xs:h-40 lg:h-52 cursor-pointer relative text-transparent border border-gray-300 rounded-2xl mt-1"
                onClick={() => handleViewImage(key)}
                key={key}
              >
                <img src={image?.attachment} className="w-full h-full rounded-2xl object-cover" alt="" />
                <section className="flex flex-col rounded-2xl w-full h-full z-20 absolute top-0 py-2 px-3">
                  {!disabled && (
                    <button type="button" className="ml-auto p-1" onClick={(e) => handleRemoveImage(key, e)}>
                      <MdClose className="text-white w-5 h-5" />
                    </button>
                  )}
                </section>
              </article>
            </div>
          ))}
        </div>
      </div>
      {viewImage && (
        <div className="flex h-screen justify-center items-center flex-col fixed left-0 top-0 w-full z-[100]">
          <div className="w-full h-screen bg-black-rgba">
            <div className="w-full h-full flex  justify-center items-center backdrop-brightness-50" onClick={handleCloseImageView}>
              <div id="gallery" className="relative w-full" data-carousel="slide">
                <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
                  <div className="duration-700 ease-in-out absolute inset-0 transition-transform transform translate-x-0 z-20" data-carousel-item>
                    <img
                      src={viewImage?.image?.attachment}
                      className="absolute block max-w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 rounded-2xl"
                      alt=""
                    />
                  </div>
                </div>
                {viewImage?.key !== 0 && (
                  <button
                    onClick={handleClickPreviousImage}
                    type="button"
                    className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    data-carousel-prev
                  >
                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                      <svg
                        className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  </button>
                )}
                {viewImage?.key + 1 < state?.[name]?.length && (
                  <button
                    onClick={handleClickNextImage}
                    type="button"
                    className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    data-carousel-next
                  >
                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                      <svg
                        className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                      </svg>
                      <span className="sr-only">Next</span>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaGallery;
