/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import AvatarEditor from "react-avatar-editor";
import { FaUserCircle } from "react-icons/fa";
import Box from "@mui/material/Box";
import { TfiClose } from "react-icons/tfi";
import { useEffect, useState, useRef } from "react";
import { Modal, Slider } from "@mui/material";
import { HiOutlinePencil } from "react-icons/hi";
import { FiTrash2 } from "react-icons/fi";
import { LuPencil, LuUpload } from "react-icons/lu";
import { AiOutlineInfoCircle } from "react-icons/ai";
import propertyService from "../services/propertyService";
import { fileToBase64 } from "../utils/fileUtils";
import { useLocation } from "react-router-dom";
import commonService from "../services/commonServices";
import { toast } from "react-toastify";
import { HiPlus } from "react-icons/hi2";
const ProfileImageUpload = ({ image = "", setImage = () => "", size = 96, isEditable = true, imageUrl = null, shape = "circle" }) => {
  const [popup, setPopup] = useState(false);
  const [selectImage, setSelectImage] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const [sizeValidation, setSizeValidation] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);
  const cropRef = useRef(null);
  const inputRef = useRef(null);
  const DOCUMENT_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/bmp"];
  const location = useLocation();

  const downloadProfileImage = async () => {
    if (imageUrl) {
      try {
        const image = await propertyService.downloadPropertyMedia(imageUrl);
        const blob = new Blob([image.data], { type: "image/jpeg" });
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        try {
          const base64String = await fileToBase64(file);
          setImage(base64String);
        } catch (error) {
          console.error("Error converting file to base64", error);
        }
      } catch (error) {
        console.log("Error downloading profile image");
      }
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 350,
    minHeight: 547.75,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "8px",
    p: 3,
    outline: "none",
  };
  const handleClose = () => {
    setPopup(false);
    setFileValidation(false);
    setSizeValidation(false);
  };

  const onClickSelectFile = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const onClickSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      setImage(dataUrl);
      let payload = {
        profile_img: {
          attachment: cropRef.current.getImage().toDataURL(),
          extension: cropRef.current.getImage().toDataURL().split(";")[0].split("/")[1],
        },
      };
      if (location.pathname === "/consumer-profile") {
        payload = { ...payload, master_user_role_id: 1 };
        uploadProfileImage(payload);
      } else if (location.pathname === "/vendor-profile") {
        payload = { ...payload, master_user_role_id: 3 };
        uploadProfileImage(payload);
      } else {
        setSelectImage(null);
        setPopup(false);
        setSlideValue(10);
        setFileValidation(false);
        setSizeValidation(false);
      }
    }
  };

  const uploadProfileImage = async (payload) => {
    try {
      await commonService.updateProfilePhoto(payload);
      toast.success("Profile image updated successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
      setSelectImage(null);
      setPopup(false);
      setSlideValue(10);
      setFileValidation(false);
      setSizeValidation(false);
    } catch (error) {
      console.log("Error uploading profile image");
    }
  };

  const onChangeInput = (e) => {
    const selectedImage = e.target.files[0];
    let fileVal = false;
    let sizeVal = false;

    if (!selectedImage) {
      setFileValidation(false);
      setSizeValidation(false);
      return;
    }
    if (!DOCUMENT_FILE_TYPES?.includes(selectedImage?.type)) {
      setImage(null);
      setFileValidation(true);
      fileVal = true;
    } else {
      setFileValidation(false);
    }
    if (!selectedImage || selectedImage?.size > 2 * 1024 * 1024) {
      setImage(null);
      setSizeValidation(true);
      sizeVal = true;
    } else {
      setSizeValidation(false);
    }
    if (!fileVal && !sizeVal) {
      setSelectImage(selectedImage);
      setFileValidation(false);
      setSizeValidation(false);
    }
  };
  useEffect(() => {
    downloadProfileImage();
  }, []);
  return (
    <>
      {!image ? (
        <div className="relative items-center flex group">
          <FaUserCircle className="text-gray-200" style={{ width: size, height: size }} />
          {isEditable && (
            <div
              className="absolute bg-black-900 bg-opacity-30 top-0 rounded-full cursor-pointer"
              onClick={() => setPopup(true)}
              style={{ width: size, height: size }}
            >
              <HiPlus className="w-16 h-16 absolute top-[50%] left-[50%] text-white" style={{ transform: "translate(-50%, -50%)" }} />
            </div>
          )}
        </div>
      ) : (
        <div className="relative" style={{ width: size, height: size }}>
          <div className="flex flex-col items-center justify-center">
            <img
              src={image}
              className={`border-2 border-primary bg-white ${shape === "circle" ? "rounded-full" : "rounded-xl"}`}
              style={{ width: size }}
            />
          </div>
          {isEditable && (
            <span
              className="bg-[#eaf1fb] text-black-900 hover:text-white rounded-full absolute -right-1 bottom-2 p-1.5 cursor-pointer hover:bg-primary"
              onClick={() => setPopup(true)}
            >
              <LuPencil className="w-4 h-4" />
            </span>
          )}
        </div>
      )}
      <div style={{ outline: "none" }}>
        <Modal open={popup} onClose={handleClose}>
          <Box sx={style}>
            <TfiClose
              onClick={() => {
                handleClose();
              }}
              className="absolute right-5 top-5 text-black-900 w-5 h-5 cursor-pointer"
            />
            {!selectImage ? (
              <div className="flex flex-col justify-between">
                <>
                  <div className="text-lg font-medium text-black-900">Profile Picture</div>
                  <div className="border-b border-gray-600 pb-5">
                    <p className="mt-3 flex gap-2 items-center text-gray-400 text-sm">
                      <AiOutlineInfoCircle className="w-4 h-4" />
                      Note
                    </p>
                    <ul className="space-y-1 list-disc list-inside pl-2 mt-3">
                      <li className={fileValidation ? "font-normal text-xs text-red-500" : "font-normal text-xs text-black-100"}>
                        Only PNG, JPEG, JPG, BMP is supported.
                      </li>
                      <li className={sizeValidation ? "font-normal text-xs text-red-500" : "font-normal text-xs text-black-100"}>
                        Image must be less than 2MB.
                      </li>
                    </ul>
                  </div>
                  {!image ? (
                    <div className="justify-center mt-5">
                      <FaUserCircle className="h-72 w-72 text-gray-200" />
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="w-72 h-72 absolute hidden group-hover:flex bg-black-900 bg-opacity-40 rounded-full cursor-pointer"
                        onClick={(e) => {
                          setPopup(true);
                          onClickSelectFile(e);
                        }}
                      >
                        <HiPlus className="w-16 h-16 text-white" />
                      </div>
                    </div>
                  ) : (
                    <img src={image} className="rounded-full mt-5" />
                  )}
                </>
                <div className="flex justify-between mt-5" style={{ width: "100%" }}>
                  <input
                    type="file"
                    ref={inputRef}
                    className="hidden"
                    onChange={onChangeInput}
                    accept="image/png, image/jpeg ,image/jpg, image/bmp"
                  />
                  {image ? (
                    <>
                      <button
                        onClick={onClickSelectFile}
                        className="text-primary font-semibold	border rounded flex items-center justify-center gap-2 text-sm w-36 h-9"
                      >
                        <HiOutlinePencil className="text-base" /> Change
                      </button>
                      <button
                        className="text-primary font-semibold	border rounded flex items-center justify-center gap-2 text-sm w-36 h-9"
                        style={{ width: "48%" }}
                        onClick={() => {
                          setImage(null);
                        }}
                      >
                        <FiTrash2 className="text-base" />
                        Remove
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onClickSelectFile}
                      className="text-primary font-semibold	border rounded flex items-center justify-center gap-2 text-sm w-full h-9"
                    >
                      <LuUpload /> Upload
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-between">
                <>
                  <div className="text-lg font-medium text-black-900">Upload Picture</div>
                  <p className="my-3 pb-3 border-b border-gray-600 text-sm text-black-100">
                    An image that helps in identifying you and lets you know when you’re signed in to your account.
                  </p>
                  <AvatarEditor
                    ref={cropRef}
                    image={selectImage}
                    style={{ width: "100%", height: "100%" }}
                    border={10}
                    borderRadius={150}
                    color={[0, 0, 0, 0.72]}
                    scale={slideValue / 10}
                    rotate={0}
                  />
                  <div className="mt-5 flex justify-center">
                    <Slider
                      min={10}
                      max={50}
                      sx={{
                        margin: "0 auto",
                        width: "100%",
                        color: "#325B22",
                      }}
                      size="medium"
                      defaultValue={slideValue}
                      value={slideValue}
                      onChange={(e) => setSlideValue(e.target.value)}
                    />
                  </div>
                </>
                <div className="flex justify-between mt-5" style={{ width: "100%" }}>
                  <>
                    <button
                      onClick={onClickSave}
                      className="text-primary font-semibold	border rounded flex items-center justify-center gap-2 text-sm w-36 h-9"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => {
                        setSelectImage(null);
                        setSlideValue(10);
                      }}
                      className="text-primary font-semibold	border rounded flex items-center justify-center gap-2 text-sm w-36 h-9"
                      style={{ width: "48%" }}
                    >
                      Cancel
                    </button>
                  </>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ProfileImageUpload;
