import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const projectsService = {
  addProject: (data) => {
    return http.post(URLS.projects.addProject, data);
  },
  projectList: (data) => {
    return http.post(URLS.projects.projectList, data);
  },
  tendorMall: (data) => {
    return http.post(URLS.projects.tendorMall, data);
  },
  projectDetails: (data) => {
    return http.post(URLS.projects.projectDetails, data);
  },
  addBid: (data) => {
    return http.post(URLS.projects.addBid, data);
  },
  dealRoom: (data) => {
    return http.post(URLS.projects.dealRoom, data);
  },
  shortlist: (data) => {
    return http.post(URLS.projects.shortlist, data);
  },
  addFaq: (data) => {
    return http.post(URLS.projects.addFaq, data);
  },
  editFaq: (data) => {
    return http.post(URLS.projects.editFaq, data);
  },
  deleteFaq: (data) => {
    return http.post(URLS.projects.deleteFaq, data);
  },
  faqList: (data) => {
    return http.post(URLS.projects.faqList, data);
  },
  myBids: (data) => {
    return http.post(URLS.projects.myBids, data);
  },
  vendorBidDetails: (data) => {
    return http.post(URLS.projects.vendorBidDetails, data);
  },
  scheduleSiteVisit: (data) => {
    return http.post(URLS.projects.scheduleSiteVisit, data);
  },
  addProposal: (data) => {
    return http.post(URLS.projects.addProposal, data);
  },
  acceptRejectProposal: (data) => {
    return http.post(URLS.projects.acceptRejectProposal, data);
  },
  extendValidity: (data) => {
    return http.post(URLS.projects.extendValidity, data);
  },
  markAsComplete: (data) => {
    return http.post(URLS.projects.markAsComplete, data);
  },
  executeDispute: (data) => {
    return http.post(URLS.projects.executeDispute, data);
  },
  editProject: (data) => {
    return http.post(URLS.projects.editProject, data);
  },
  getProjectCountryList: () => {
    return http.get(URLS.projects.getProjectCountryList);
  },
  executeDisputeV1: (data) => {
    return http.post(URLS.projects.executeDisputeV1, data);
  },
  acceptRejectProposalV1: (data) => {
    return http.post(URLS.projects.acceptRejectProposalV1, data);
  },
  addBidV1: (data) => {
    return http.post(URLS.projects.addBidV1, data);
  },
  addProposalV1: (data) => {
    return http.post(URLS.projects.addProposalV1, data);
  },
  myBidsV1: (data) => {
    return http.post(URLS.projects.myBidsV1, data);
  },
};

export default projectsService;
