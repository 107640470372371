export const URLS = {
  auth: {
    login: "user/login",
    signup: "user/signup",
    sendotp: "user/send-otp",
    verifyotp: "user/verify-otp",
    emailCheck: "user/isEmail-exists",
    consumerKyc: "consumer/kyc-add",
    refreshToken: "user/refresh-token",
    getConsumerDetails: "consumer/get-details",
    consumerKycV1: "consumer/kyc-add/v1",
    hideProfileDetails: "consumer/hide-profile-details",
  },
  property: {
    addProperty: "property/add",
    uploadPropertyMedia: "property/media-upload",
    saveAsDraftProperty: "property/save-draft",
    getPropertyList: "property/list",
    getPropertyDetails: "property/view",
    editProperty: "/property/edit",
    editPropertyMedia: "/property/edit-media",
  },
  project: {
    addProject: "project/solar-add",
    uploadProjectMedia: "project/media-upload",
    getProjectList: "project/solar-list",
    getProjectDetails: "project/solar-view",
    saveAsDraftProject: "project/solar-draft",
    editProject: "project/solar-edit",
  },
  buySell: {
    getMarketplaceList: "project/sell-list",
    getProjectList: "project/my-project-list",
    addExistingSolar: "project/sell-add",
    saveAsDraftExistingSolar: "project/sell-draft",
    editExistingSolar: "project/sell-edit",
    getProjectDetails: "project/sell-view",
    extendBidValidity: "project/extend-bid-validity",
    bidList: "sell_bid/bid-list",
    highestBid: "sell_bid/highest-value",
    addBid: "sell_bid/add",
    viewBid: "sell_bid/view-project-bid",
    projectBidList: "sell_bid/project-bid-list",
    acceptRejectBid: "sell_bid/accept-reject-bid",
    raiseBid: "sell_bid/raise-bid-details",
    addFaq: "sell_bid/add-faq",
    editFaq: "sell_bid/update-faq",
    deleteFaq: "sell_bid/delete-faq",
    faqList: "sell_bid/faq-list",
    getProjectCountryList: "project/country-list",
    getVendorCountryList: "project/user/country-list",
  },
  vendorKyc: {
    addVendorKyc: "vendor/kyc-add",
    getVendorKycDetails: "vendor/kyc-view",
    getVendorKycStatus: "vendor/kyc-status",
    hideProfileDetails: "vendor/hide-profile-details",
  },
  projects: {
    addProject: "solar_project/add",
    projectList: "solar_project/my-project-list",
    tendorMall: "solar_project/tender-mall-list",
    projectDetails: "solar_project/solar-view",
    addBid: "solar_bid/add",
    dealRoom: "solar_bid/deal-room",
    shortlist: "solar_bid/shortlist",
    scheduleSiteVisit: "solar_bid/site-visit",
    addFaq: "solar_bid/add-faq",
    editFaq: "solar_bid/update-faq",
    deleteFaq: "solar_bid/delete-faq",
    faqList: "solar_bid/faq-list",
    myBids: "solar_bid/my-list",
    vendorBidDetails: "solar_bid/view-details",
    addProposal: "solar_bid/proposal/add",
    acceptRejectProposal: "solar_bid/proposal/accept-reject",
    extendValidity: "solar_bid/proposal/extend-validity",
    markAsComplete: "solar_bid/mark-complete",
    executeDispute: "solar_bid/project/execute-dispute",
    editProject: "solar_project/edit",
    getProjectCountryList: "solar_project/project-country-list",
    executeDisputeV1: "solar_bid/project/v1/execute-dispute",
    acceptRejectProposalV1: "solar_bid/proposal/v1/accept-reject",
    addBidV1: "solar_bid/v1/add",
    addProposalV1: "solar_bid/proposal/v1/add",
    myBidsV1: "solar_bid/v1/my-list",
  },
  admin: {
    auth: {
      sendotp: "admin/admin_login/send-otp",
      verifyotp: "admin/admin_login/verify-otp",
      login: "admin/admin_login/login",
      refreshToken: "admin/admin_login/refresh-token",
    },
    buySell: {
      currentProjects: "admin/project/current-sell-project",
      pastProjects: "admin/project/past-sell-project",
      projectDetails: "admin/project/sell-project-details",
      changeProjectStatus: "admin/project/change-project-status",
      getProjectCountryList: "admin/project/country-list",
    },
    userManagement: {
      consumerList: "admin/user/consumer-list",
      vendorList: "admin/user/vendor-list",
      consumerDetails: "admin/user/consumer-detail",
      vendorDetails: "admin/user/vendor-detail",
      consumerKycStatusUpdate: "admin/user/consumer-kyc-status-update",
      vendorKycStatusUpdate: "admin/user/vendor-kyc-status-update",
      getCounsumerCountryList: "admin/user/consumer-country-list",
      getVendorCountryList: "admin/user/vendor-country-list",
      addVendorRating: "admin/user/vendor-rating/add",
      editVendorRating: "admin/user/vendor-rating/edit",
    },
    projects: {
      currentProjects: "admin/project/current-solar-project",
      projectDetails: "admin/project/solar-view",
      changeSubStatus: "admin/project/change-solar-sub-status",
      uploadReport: "admin/project/upload-assessment",
      dealRoom: "admin/project/deal-room",
      removeBid: "admin/project/remove-bid",
      executeArchive: "admin/project/change-solar-status",
      shortlist: "admin/project/shortlist",
      scheduleSiteVisit: "admin/project/site-visit",
      extendValidity: "admin/project/proposal/extend-validity",
      createProject: "admin/project/add",
      editProject: "admin/project/edit",
      acceptRejectProposal: "admin/project/proposal/accept-reject",
    },
  },
  common: {
    updateProfilePhoto: "user/update-profile",
  },
  masterData: {
    auth: {
      getUserStatus: "master/user-status-auth/list",
      getUserRoles: "master/user-role/list",
      getDocTypes: "master/doc/types",
      getDocSubTypes: "master/sub-doc/types",
      getCountryList: "master/countries/list",
      getCountryWithNationality: "master/countries-with-nationality",
    },
    property: {
      getPropertyStatus: "master/property/status",
      getPropertyTypes: "master/property/types",
      getIndustryTypes: "master/industry/types",
      getBuildingYears: "master/building/years",
      getOccupantsNumbers: "master/occupants/numbers",
      getRoofAreaUnits: "master/roof-area/units",
      getRoofLengthUnits: "master/roof-length/units",
      getRoofBreadthUnits: "master/roof-breadth/units",
    },
    project: {
      getProjectTypes: "master/project/types",
      getProjectCategories: "master/project/categories",
      getPropertyMasterList: "master/property/master-list",
    },
    buySell: {
      getAgreementTypes: "master/agreements",
      getCapacityUnits: "master/project/capacity-units",
      getCurrencyList: "master/currencies",
      getModuleSpecification: "master/module-specification",
      getGridConnections: "master/project/grid-connections",
      getCostSharingUnits: "master/cost-sharing-unit",
    },
    vendorKyc: { getVendorBidUnits: "master/vendor/bid-units", getNationalities: "master/nationalities" },
    projects: {
      getPropertyType: "master/property/types",
      getDisputeReasons: "solar_project/masters/project-disputes-reason/list",
      getProjectRejectReasons: "solar_project/masters/proposal-reject-reason/list",
      getProjectModels: "solar_project/masters/project-model/list",
      getProjectBidStatus: "solar_project/masters/bid-status/list",
      getBidProjectStatus: "solar_project/masters/bid-project-status/list",
    },
    admin: {
      buySell: {
        getRejectReasons: "master/project/reject-reasons",
      },
      userManagement: {
        getUserRejectReasons: "admin/masters/user-reject-reason/list",
      },
      projects: {
        projectStatus: "solar_project/masters/solar-project-status/list",
        projectSubStatus: "solar_project/masters/solar-project-sub-status/list",
      },
    },
  },
};
