import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthTokenCookie } from "../utils/cookieUtils";
import { useSelector } from "react-redux";

function PublicRoute({ children }) {
  const navigate = useNavigate();
  const userData = getAuthTokenCookie();
  const userRole = useSelector((state) => state?.user?.userData?.loggedIn);
  const isKycDone = useSelector((state) => state?.user?.userData?.isKycDone);

  useEffect(() => {
    if (userData && userRole === "Customer" && isKycDone) {
      navigate("/buy-sell");
    } else if (userData && userRole === "Customer" && !isKycDone) {
      navigate("/signup");
    } else if (userData && userRole === "Admin") {
      navigate("/admin/dashboard");
    }
  }, [userData, navigate, userRole, isKycDone]);

  return !userData ? children : null;
}

export default PublicRoute;
