/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import HeadingTitle from "../../components/HeadingTitle";
import CustomSelect from "../../components/CustomSelect";
import MediaGallery2 from "../../components/MediaGallery2";
import MapComponent3 from "../../components/MapComponent3";
import CustomFileInput from "../../components/CustomFileInput";
import masterDataService from "../../services/masterDataService";
import { t } from "i18next";
import GooglePlaceAutocomplete from "../../components/GooglePlaceInput";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Carousel } from "react-responsive-carousel";
import { TfiClose } from "react-icons/tfi";
import CustomInputSelect2 from "../../components/CustomInputSelect2";
import CustomCheckbox2 from "../../components/CustomCheckbox2";
import { useNavigate } from "react-router-dom";
const CreateProject = ({
  setState,
  control,
  errors,
  register,
  setValue,
  watch,
  state,
  setError,
  clearErrors,
  resetFormField,
  resetField,
  setCountryCode,
  innerPolygons,
  outerPolygons,
  setInnerPolygons,
  setOuterPolygons,
  trigger,
  getValues,
}) => {
  const navigate = useNavigate();
  const DOCUMENT_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(19);
  // const handleOpen = () => setOpen(true);
  const onChangRrooftopAreaUnit = watch("rooftopAreaUnit");
  const handleClose = () => {
    setOpen(false);
  };
  const setCenter = (e) => {
    setState({ center: e });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
    width: "55%",
  };
  const getPropertyTypes = async () => {
    try {
      let response = await masterDataService.getPropertyType();
      if (response.data.status) {
        let data = [];
        response.data.data.forEach((ele) => {
          data.push({ label: ele.name, value: ele.id });
        });
        setState({ propertyTypes: data });
      }
    } catch (error) {
      console.log("Error fetching property types", error);
    }
  };
  const getProjectModels = async () => {
    try {
      let response = await masterDataService.getProjectModels();
      if (response.data.status) {
        let data = [];
        response.data.data.forEach((ele) => {
          data.push({ label: ele.name, value: String(ele.id) });
        });
        setState({ projectModels: data });
      }
    } catch (error) {
      console.log("Error fetching project models", error);
    }
  };
  const fetchRoofAreaUnits = async () => {
    try {
      let response = await masterDataService.getRoofAreaUnits();
      if (response.data.status) {
        let data = [];
        response.data.data.forEach((ele) => {
          data.push({ label: ele.name, value: ele.id });
        });
        setState({ roofAreaUnits: data });
      }
    } catch (error) {
      console.log("Error fetching roof area units", error);
    }
  };

  const getCoordinates = (latlng) => {
    setCenter(latlng);
    setZoom(19);
  };
  useEffect(() => {
    getPropertyTypes();
    fetchRoofAreaUnits();
    getProjectModels();
  }, []);
  useEffect(() => {
    if (state?.mapArea?.[0]) {
      if (onChangRrooftopAreaUnit?.value === 1) {
        setValue("rooftopArea", state?.mapArea?.[0]?.toFixed(2));
      } else {
        setValue("rooftopArea", state?.mapArea?.[1]?.toFixed(2));
      }
      resetFormField("rooftopArea");
    } else {
      setValue("rooftopArea", null);
    }
  }, [state?.mapArea, onChangRrooftopAreaUnit]);

  return (
    <div className="h-full">
      <div className="mb-6">
        <div>
          <HeadingTitle headingtitle="Project Details" />
          <div className="grid xs:grid-cols-1 sm:grid-cols-3 gap-4 mb-5">
            <div>
              <CustomInput required name="projectTitle" label="Project Title" register={register} setValue={setValue} watch={watch} errors={errors} />
            </div>
            <div>
              <CustomSelect
                required
                name="propertyType"
                label="Property Type"
                control={control}
                register={register}
                errors={errors}
                options={state?.propertyTypes}
              />
            </div>
            <div>
              <GooglePlaceAutocomplete
                required
                errors={errors}
                label="Property Location"
                register={register}
                name="location"
                setValue={setValue}
                watch={watch}
                getCoordinates={getCoordinates}
                clearErrors={clearErrors}
                setError={setError}
                setCountryCode={setCountryCode}
                desc={t("app.tooltip.addBuySell.location")}
                pos={"top"}
              />
            </div>
          </div>
          <div className="grid xs:grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
            {/* <CustomMultiSelect control={control} name="projectModels" label="Project Model" /> */}
            <CustomCheckbox2
              options={state?.projectModels}
              label="Project Model"
              name="projectModel"
              errors={errors}
              required
              control={control}
              trigger={trigger}
              clearErrors={clearErrors}
              setValue={setValue}
              watch={watch}
            />
          </div>
        </div>
      </div>
      <MediaGallery2
        required
        title="Project Images"
        subTitle="(at least 2 required)"
        state={state}
        name="projectImages"
        errors={errors}
        setState={setState}
        setError={setError}
        clearErrors={clearErrors}
        type="base64"
        control={control}
      />
      {/* <MediaGallery
        required
        title="Project Images"
        subTitle="(at least 2 required)"
        state={state}
        name="projectImages"
        errors={errors}
        setState={setState}
        setError={setError}
        clearErrors={clearErrors}
        type="base64"
        control={control}
      /> */}
      <div className="mb-6">
        <HeadingTitle headingtitle="Additional Documents" subTitle="(optional)" />
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-5 mt-3">
          <CustomFileInput
            label="Document 1"
            inputName="document1"
            fileName="document1File"
            fileTypes={DOCUMENT_FILE_TYPES}
            size={2}
            register={register}
            errors={errors}
            setValue={setValue}
            resetField={resetField}
            resetFormField={resetFormField}
            setError={setError}
            clearErrors={clearErrors}
            watch={watch}
          />
          <CustomFileInput
            label="Document 2"
            inputName="document2"
            fileName="document2File"
            fileTypes={DOCUMENT_FILE_TYPES}
            size={2}
            register={register}
            errors={errors}
            setValue={setValue}
            resetField={resetField}
            resetFormField={resetFormField}
            setError={setError}
            clearErrors={clearErrors}
            watch={watch}
          />
          <CustomFileInput
            label="Document 3"
            inputName="document3"
            fileName="document3File"
            fileTypes={DOCUMENT_FILE_TYPES}
            size={2}
            register={register}
            errors={errors}
            setValue={setValue}
            resetField={resetField}
            resetFormField={resetFormField}
            setError={setError}
            clearErrors={clearErrors}
            watch={watch}
          />
        </div>
      </div>
      {state?.center && (
        <div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
            <div>
              <CustomInputSelect2
                disabled={true}
                required
                type="number"
                label="Rooftop Area"
                inputName="rooftopArea"
                selectName="rooftopAreaUnit"
                register={register}
                control={control}
                options={state?.roofAreaUnits}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            </div>
            <div className="xs:mt-0 sm:mt-6 flex xs:justify-end sm:justify-normal items-center space-x-2">
              {/* <>
                <button
                  type="button"
                  className="tracking-wide font-medium bg-white border border-primary text-primary py-1.5 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                >
                  <span className="text-sm font-medium text-primary">Done</span>
                </button>
              </> */}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mb-5">
            <MapComponent3
              state={state}
              setState={setState}
              center={state?.center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
              errors={errors}
              watch={watch}
              setValue={setValue}
              options={state?.roofAreaUnits}
              control={control}
              register={register}
              outerPolygons={outerPolygons}
              innerPolygons={innerPolygons}
              setOuterPolygons={setOuterPolygons}
              setInnerPolygons={setInnerPolygons}
            />
          </div>
        </div>
      )}
      <div className="my-10 flex xs:justify-center xl:justify-end space-x-3">
        <button
          type="button"
          onClick={() => navigate("/projects")}
          className="mt-2 tracking-wide font-medium bg-white border border-primary text-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
        >
          <span className="text-sm font-medium text-primary">Back</span>
        </button>
        <button
          disabled={!!Object?.keys(errors)?.length}
          type="submit"
          className="mt-2 tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
        >
          <span className="text-sm font-medium text-white">Submit</span>
        </button>
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <TfiClose
            onClick={() => {
              handleClose();
            }}
            className="absolute right-5 top-5 text-black-900 w-5 h-5 cursor-pointer"
          />
          <div className="flex flex-col projects-graph">
            <div className="flex justify-between items-center w-full border-b border-gray-600 pb-2 pr-5 mb-5">
              <span className="text-base text-black-900 font-medium">Rooftop area : 4500sq m</span>
              <button
                type="submit"
                className="tracking-wide text-sm font-medium bg-white py-1 px-3 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none text-primary border border-primary"
              >
                <span>Calculate again</span>
              </button>
            </div>

            <Carousel showArrows={true} showStatus={false} showThumbs={false} className="mb-10">
              <div className="text-left">
                <p className="text-base text-black-100 font-bold mb-3">
                  Great, you are taking your first step towards a greener future, your solar project promises both financial and environmental
                  rewards!
                </p>
                <p className="text-sm text-black-100 font-normal">
                  Notice the positive impact your project will make on the environment and energy conservation!
                </p>
                <div className="mt-10 mb-5">
                  <img src={require("../../assets/images/CO2reductionmeter.png")} className="object-cover mx-auto" alt="" />
                </div>
                <button
                  type="submit"
                  className="tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-end ml-auto focus:shadow-outline focus:outline-none text-white text-sm mt-10"
                >
                  <span>Next</span>
                </button>
              </div>
              {/* <div>
                <p className="text-base text-black-100 font-bold mb-3">Lorem Ipsum Benefits</p>
                <p className="text-sm text-black-100 font-normal">Maecenas in neque justo. Ut ac tincidunt lorem, non posuere metus.</p>
                <div className="mt-10 mb-5">
                  <img src={require("../../assets/images/assessment1.png")} className="object-cover mx-auto" alt="" />
                </div>
                <button
                  type="submit"
                  className="tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-end ml-auto focus:shadow-outline focus:outline-none text-white text-sm mt-10"
                >
                  <span>Got It</span>
                </button>
              </div> */}
            </Carousel>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateProject;
