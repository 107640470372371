import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/userSlice";
import applicationSlice from "./reducers/applicationSlice";
import { thunk } from "redux-thunk";

const reducer = combineReducers({
  user: userSlice,
  application: applicationSlice,
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
