import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: JSON?.parse(localStorage?.getItem("userData")),
    vendorData: JSON?.parse(localStorage?.getItem("vendorData")),
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setVendorData: (state, action) => {
      state.vendorData = action.payload;
    },
  },
});

export const { setUserData, setVendorData } = userSlice.actions;
export default userSlice.reducer;
