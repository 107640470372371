const HeadingTitle = ({ required, headingtitle, subTitle = "" }) => {
  return (
    <div>
      <h2 className="xs:text-lg sm:text-2xl font-bold text-black-100 relative w-full inline-block mb-8 border-b-[1px] border-[#CFE6C5] h-10">
        <span className="bg-white absolute left-0 top-6 pr-8">
          {headingtitle}
          {required && <span className="text-red-500">*</span>} <span className="xs:text-sm sm:text-base font-normal">{subTitle}</span>
        </span>
      </h2>
    </div>
  );
};
export default HeadingTitle;
