import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const CustomInput = ({
  name,
  label,
  required,
  placeholder,
  disabled,
  SideText,
  type,
  register = () => "",
  errors = () => "",
  setValue = () => "",
  watch = () => "",
  desc = "",
  pos = "bottom",
  delay = 1000,
}) => {
  const baseClass =
    "text-sm placeholder:text-sm placeholder:text-gray-10 border border-gray-300 appearance-none rounded-full block w-full text-black-100 py-2 2xl:py-2.5 px-4 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed disabled:bg-green-200 disabled:text-slate-500 disabled:shadow-none";
  const sidetextClass = "pr-14";
  const errorClass = errors?.[name] ? "border-red-600" : "focus:border-primary";
  const adminClass = useSelector((state) => state?.user?.userData?.loggedIn) === "Admin" ? "disabled:border-none" : "border border-gray-300";

  const onChangeInput = (e) => {
    register(name).onChange(e);
    if (type === "number") {
      const sanitizedValue = e.target.value.replace(/\D/g, "");
      setValue(name, sanitizedValue);
    } else if (type === "decimal") {
      const sanitizedValue = e.target.value.replace(/(^\d*\.?\d*).*|[^0-9.]/g, "$1");
      setValue(name, sanitizedValue);
    }
  };

  return (
    <div>
      {label && (
        <label className="block tracking-wide text-black-100 text-xs font-normal mb-2">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="flex relative">
        <Tooltip title={desc} placement={pos} enterDelay={delay}>
          <input
            disabled={disabled ? true : false}
            className={`${baseClass} ${errorClass} ${!!SideText && sidetextClass} ${adminClass}`}
            placeholder={placeholder}
            // required={required}
            {...register(name)}
            value={watch(name) || ""}
            onChange={onChangeInput}
          />
        </Tooltip>
        {SideText && <span className="absolute right-3 top-2 text-sm font-normal text-black-900">{SideText}</span>}
      </div>
      {errors?.[name] && <span className="block my-2 text-xs font-normal text-red-500 ">{errors?.[name]?.message}</span>}
    </div>
  );
};
export default CustomInput;
