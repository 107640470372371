/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { RxPencil2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { TfiClose, TfiTrash } from "react-icons/tfi";
import Modal from "@mui/material/Modal";
import { FiPlus } from "react-icons/fi";
import Box from "@mui/material/Box";
import buySellService from "../services/buySellService";
import { useLocation } from "react-router-dom";
import projectsService from "../services/projectsService";

const AddFaqModal = ({ addFaqModal, setAddFaqModal, id }) => {
  const location = useLocation();
  const currentPath = location?.pathname;
  const [showFaqInput, setShowFaqInput] = useState(false);
  const [onChangeQ, setOnChangeQ] = useState("");
  const [onChangeA, setOnChangeA] = useState("");
  const [faqList, setFaqList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [faqId, setFaqId] = useState(null);
  const handleCloseFaqModal = () => setAddFaqModal(false);
  const projectPaths = ["/projects-deal-room", "/project-details"];

  const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
    // width: 700,
  };

  const getFaqList = async () => {
    let payload;
    if (projectPaths.includes(currentPath)) {
      payload = {
        solar_project_id: id,
      };
    } else {
      payload = {
        project_id: id,
      };
    }
    let response;
    try {
      if (projectPaths.includes(currentPath)) {
        response = await projectsService.faqList(payload);
      } else {
        response = await buySellService.faqList(payload);
      }
      if (response.data.status) {
        setFaqList(response?.data?.data);
      }
    } catch (error) {
      console.log("Error fetching faq list", error);
    }
  };
  const deleteFaq = async (id) => {
    let payload = {
      faq_id: id,
    };
    try {
      let response;
      if (projectPaths.includes(currentPath)) {
        response = await projectsService.deleteFaq(payload);
      } else {
        response = await buySellService.deleteFaq(payload);
      }
      if (response.data.status) {
        toast.success("FAQ deleted successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
        getFaqList();
      }
    } catch (error) {
      console.log("Error fetching faq list", error);
    }
  };
  const editFaq = async () => {
    let payload = {
      faq_id: faqId,
      question: onChangeQ,
      answer: onChangeA,
    };
    try {
      let response;
      if (projectPaths.includes(currentPath)) {
        response = await projectsService.editFaq(payload);
      } else {
        response = await buySellService.editFaq(payload);
      }
      if (response.data.status) {
        toast.success("FAQ edited successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
        setIsEdit(false);
        setShowFaqInput(false);
        getFaqList();
        setOnChangeQ("");
        setOnChangeA("");
      }
    } catch (error) {
      console.log("Error fetching faq list", error);
    }
  };

  const addFaq = async () => {
    let payload;
    if (projectPaths.includes(currentPath)) {
      payload = {
        solar_project_id: id,
        question: onChangeQ,
        answer: onChangeA,
      };
    } else {
      payload = {
        project_id: id,
        question: onChangeQ,
        answer: onChangeA,
      };
    }
    try {
      let response;
      if (projectPaths.includes(currentPath)) {
        response = await projectsService.addFaq(payload);
      } else {
        response = await buySellService.addFaq(payload);
      }
      if (response.data.status) {
        setShowFaqInput(false);
        getFaqList();
        setOnChangeQ("");
        setOnChangeA("");
        toast.success("FAQ added successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.log("Error adding faq", error);
    }
  };
  const handleEditFaq = (val) => {
    setFaqId(val?.id);
    setIsEdit(true);
    setOnChangeQ(val?.question);
    setOnChangeA(val?.answer);
    setShowFaqInput(true);
  };
  useEffect(() => {
    getFaqList();
  }, []);
  return (
    <Modal
      open={addFaqModal}
      onClose={handleCloseFaqModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ zIndex: "98" }}
      className="fixed z-10 inset-0 flex items-center justify-center"
    >
      <Box sx={style} className="relative overflow-hidden xs:w-[95%] xsm:max-w-screen-md xsm:w-full m-4">
        <TfiClose
          onClick={() => {
            setAddFaqModal(false);
          }}
          className="absolute right-5 top-5 text-black-900 w-5 h-5 cursor-pointer"
        />
        <h3 className="text-black-900 text-xl font-medium">FAQs</h3>
        <div className="flex justify-end">
          {faqList?.length > 0 && (
            <button
              type="button"
              onClick={() => setShowFaqInput(true)}
              disabled={showFaqInput}
              className="tracking-wide font-medium py-1.5 px-3 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none text-primary border border-primary disabled:cursor-not-allowed disabled:opacity-50"
            >
              <span className="text-sm font-medium flex items-center">
                <FiPlus className="w-5 h-5 mr-2" /> Add
              </span>
            </button>
          )}
        </div>
        <section className="faqmodal container relative mx-auto h-[470px] overflow-auto pr-3 mt-6">
          {showFaqInput && (
            <div className="mb-6">
              <div className="rounded-xl bg-[#F1F7EF]  w-full select-none border border-gray-300 px-4 py-4 text-black-900 transition duration-300">
                <div className="mb-5 flex space-x-3">
                  <div className="flex items-center justify-center p-1 rounded-full bg-white w-11 h-10">
                    <span className="text-primary font-bold text-base">Q</span>
                  </div>
                  <input
                    value={onChangeQ}
                    onChange={(e) => setOnChangeQ(e.target.value)}
                    className="text-sm placeholder:text-sm placeholder:text-gray-100 appearance-none rounded-lg block w-full text-black-100 border border-gray-300 py-2 px-4 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed disabled:bg-green-200 disabled:text-slate-500 disabled:border-green-100 disabled:shadow-none"
                  />
                </div>
                <div className="mb-5 flex space-x-3">
                  <div className="flex items-center justify-center p-1 rounded-full bg-white w-11 h-10">
                    <span className="text-primary font-bold text-base">A</span>
                  </div>
                  <textarea
                    value={onChangeA}
                    onChange={(e) => setOnChangeA(e.target.value)}
                    className="text-sm placeholder:text-sm placeholder:text-gray-100 appearance-none rounded-lg block w-full text-black-100 border border-gray-300 py-2 px-4 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed disabled:bg-green-200 disabled:text-slate-500 disabled:border-green-100 disabled:shadow-none"
                    rows={4}
                  />
                </div>
                <div className="flex space-x-2 text-primary justify-end mt-5">
                  <button
                    type="button"
                    onClick={isEdit ? editFaq : addFaq}
                    disabled={onChangeA.length === 0 || onChangeQ.length === 0}
                    className="text-sm tracking-wide font-medium bg-primary py-1 px-4 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none text-white border border-primary disabled:cursor-not-allowed disabled:bg-gray-600 disabled:border-gray-600"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShowFaqInput(false);
                      setIsEdit(false);
                      setOnChangeQ("");
                      setOnChangeA("");
                    }}
                    className="text-sm tracking-wide font-medium hover:bg-primary py-1 px-4 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none text-primary hover:text-white border border-primary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {faqList?.length > 0 ? (
            <>
              {faqList.map((val) => (
                <div className="mb-6" key={val?.id}>
                  <div className="rounded-t-xl bg-[#F1F7EF]  w-full select-none border border-gray-300 px-4 py-4 text-black-900 transition duration-300 flex items-center justify-between">
                    <h4 className="text-sm font-medium">{val?.question}</h4>
                    <div className="flex space-x-3 text-primary">
                      <span className="relative flex-shrink-0 cursor-pointer" onClick={() => handleEditFaq(val)}>
                        <RxPencil2 className="w-5 h-5" />
                      </span>
                      <span className="relative flex-shrink-0 cursor-pointer" onClick={() => deleteFaq(val?.id)}>
                        <TfiTrash className="w-5 h-5" />
                      </span>
                    </div>
                  </div>
                  <div className="inline-flex w-full rounded-b-xl border-x border-b border-solid border-gray-300 bg-white px-4 py-4 text-black-100 text-sm font-normal">
                    <h5>{val?.answer}</h5>
                  </div>
                </div>
              ))}
            </>
          ) : (
            !showFaqInput && (
              <div className="text-center justify-center items-center flex flex-col h-[440px] space-y-6">
                <img className="object-cover object-center relative" alt="" src={require("../assets/images/no-faq-data.webp")} />
                <span className="text-base text-black-900 font-medium my-2 xs:px-5 sm:px-36">
                  It seems that no FAQs have been added yet. Would you like to add some?
                </span>
                <button
                  type="button"
                  onClick={() => setShowFaqInput(true)}
                  className="tracking-wide font-medium hover:bg-primary py-1 px-2.5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none text-primary hover:text-white border border-primary"
                >
                  <span className="text-sm font-normal flex items-center">
                    <FiPlus className="w-6 h-6 mr-1" /> Add
                  </span>
                </button>
              </div>
            )
          )}
        </section>
      </Box>
    </Modal>
  );
};

export default AddFaqModal;
