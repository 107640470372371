import propertyService from "../services/propertyService";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file || !(file instanceof File)) {
      reject(new Error("Invalid file"));
      return;
    }

    const reader = new FileReader();

    reader.onload = function (event) {
      resolve(event.target.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

const downloadFile = async (downloadUrl, docName) => {
  const imgTypes = ["jpeg", "jpg", "png"];
  const appTypes = ["pdf"];
  let file = await propertyService.downloadPropertyMedia(downloadUrl);
  let dot = downloadUrl?.split(".");
  let type = dot[dot?.length - 1];
  let blob = null;
  if (appTypes?.includes(type)) {
    blob = new Blob([file.data], { type: "application/pdf" });
  } else if (imgTypes?.includes(type)) {
    blob = new Blob([file.data], { type: `image/${type}` });
  }
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = docName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const getFileUrl = async (downloadUrl) => {
  const imgTypes = ["jpeg", "jpg", "png"];
  const appTypes = ["pdf"];
  let file = await propertyService.downloadPropertyMedia(downloadUrl);
  let dot = downloadUrl?.split(".");
  let type = dot[dot?.length - 1];
  let blob = null;
  if (appTypes?.includes(type)) {
    blob = new Blob([file.data], { type: "application/pdf" });
    blob = new File([blob], `document.pdf`, { type: "application/pdf" });
  } else if (imgTypes?.includes(type)) {
    blob = new Blob([file.data], { type: `image/${type}` });
    blob = new File([blob], `doc.${type}`, { type: `image/${type}` });
  }
  let newBase64 = null;
  try {
    const base64String = await fileToBase64(blob);
    newBase64 = removeSubstring(base64String);
    function removeSubstring(originalString) {
      const substringToRemove = "dataapplication/pdfbase64";
      const modifiedString = originalString.replace(substringToRemove, "");
      return modifiedString;
    }
  } catch (error) {
    console.error("Error converting file to base64", error);
  }
  return [blob, newBase64];
};
const downloadImage = async (url) => {
  let base64String = null;
  let image = await propertyService.downloadPropertyMedia(url);
  const blob = new Blob([image.data], { type: "image/jpeg" });
  const file = new File([blob], "image.jpg", { type: "image/jpeg" });
  try {
    base64String = await fileToBase64(file);
  } catch (error) {
    console.error("Error converting file to base64", error);
  }
  return base64String;
};

export { fileToBase64, downloadFile, getFileUrl, downloadImage };
