import React, { useRef, useState, useCallback, useEffect } from "react";
import { GoogleMap, DrawingManager, Polygon, Marker } from "@react-google-maps/api";
import * as turf from "@turf/turf";
import plus from "../assets/images/svg/plus.svg";
import minus from "../assets/images/svg/minus.svg";
import fullscreen from "../assets/images/svg/fullscreen.svg";
import notfullscreen from "../assets/images/svg/notfullscreen.svg";

import CustomPopup from "./CustomPopup";
import CustomInputSelect2 from "./CustomInputSelect2";

const MapComponent3 = ({
  center = null,
  setCenter,
  setState,
  zoom,
  setZoom,
  errors,
  watch,
  setValue,
  options,
  control,
  register,
  innerPolygons,
  outerPolygons,
  setInnerPolygons,
  setOuterPolygons,
}) => {
  const mapRef = useRef(null);

  const [action, setAction] = useState(null);
  const [error, setError] = useState(false);
  const [drawingManager, setDrawingManager] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const mapStyles = {
    normal: {
      height: "35rem",
      width: "100%",
    },
    fullScreen: {
      position: "fixed",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      zIndex: 1000,
    },
  };
  const mapContainerStyle = isFullScreen ? mapStyles.fullScreen : mapStyles.normal;
  const handleFullScreenToggle = () => {
    setIsFullScreen((prevFullScreen) => !prevFullScreen);
  };
  const increaseZoom = () => {
    if (zoom < 21) {
      setZoom((prev) => prev + 1);
    }
  };

  const decreseZoom = () => {
    if (zoom >= 1) {
      setZoom((prev) => prev - 1);
    }
  };
  const mapOptions = {
    streetViewControl: false,
    mapTypeId: "satellite",
    tilt: 0,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
  };
  const handleMapLoad = (map) => {
    setIsLoaded(true);
    mapRef.current = map;
    map.addListener("zoom_changed", () => {
      const newZoom = map.getZoom();
      setZoom(newZoom);
    });
  };

  const handleOverlayComplete = (event) => {
    try {
      const newPolygon = event.overlay;
      const path = newPolygon
        .getPath()
        .getArray()
        .map(({ lat, lng }) => ({ lat: lat(), lng: lng() }));

      if (path.length > 0 && (path[0].lat !== path[path.length - 1].lat || path[0].lng !== path[path.length - 1].lng)) {
        path.push({ lat: path[0].lat, lng: path[0].lng });
      }

      newPolygon.setMap(null);

      const newPolygonPath = path.map((coord) => [coord.lng, coord.lat]);
      const newPolygonGeoJSON = turf.polygon([newPolygonPath]);

      if (action === "inner") {
        const isValidWithin = outerPolygons.some((outerPath) => {
          const outerPolygonPath = outerPath.map((coord) => [coord.lng, coord.lat]);
          const outerPolygonGeoJSON = turf.polygon([outerPolygonPath]);
          return turf.booleanWithin(newPolygonGeoJSON, outerPolygonGeoJSON);
        });

        if (!isValidWithin) {
          setError("The marked obstacle is not inside any marked rooftop area.");
          return;
        }

        const doesOverlap = innerPolygons.some((innerPath) => {
          const innerPolygonPath = innerPath.map((coord) => [coord.lng, coord.lat]);
          const innerPolygonGeoJSON = turf.polygon([innerPolygonPath]);
          return turf.booleanOverlap(newPolygonGeoJSON, innerPolygonGeoJSON) || turf.intersect(newPolygonGeoJSON, innerPolygonGeoJSON);
        });

        if (doesOverlap) {
          setError("The new obstacle overlaps with an existing obstacle.");
          return;
        }

        setInnerPolygons((prev) => [...prev, path]);
      } else {
        const doesOverlapWithOuter = outerPolygons.some((outerPath) => {
          const outerPolygonPath = outerPath.map((coord) => [coord.lng, coord.lat]);
          const outerPolygonGeoJSON = turf.polygon([outerPolygonPath]);
          return turf.booleanOverlap(newPolygonGeoJSON, outerPolygonGeoJSON) || turf.intersect(newPolygonGeoJSON, outerPolygonGeoJSON);
        });

        if (doesOverlapWithOuter) {
          setError("The marked area overlaps with an existing area.");
          return;
        }

        setOuterPolygons((prev) => [...prev, path]);
      }
    } catch (error) {
      console.error("Error handling overlay complete:", error);
    }
  };
  const handleDrawingManagerLoad = (drawingManager) => {
    setDrawingManager(drawingManager);
  };

  const handlePolygonClick = (index, isOuter) => {
    if (action === "erase") {
      if (isOuter) {
        const outerPolygonPath = outerPolygons[index].map((coord) => [coord.lng, coord.lat]);
        const outerPolygonGeoJSON = turf.polygon([outerPolygonPath]);

        setOuterPolygons((prev) => prev.filter((_, i) => i !== index));
        setInnerPolygons((prev) =>
          prev.filter((innerPath) => {
            const innerPolygonPath = innerPath.map((coord) => [coord.lng, coord.lat]);
            const innerPolygonGeoJSON = turf.polygon([innerPolygonPath]);
            return !turf.booleanWithin(innerPolygonGeoJSON, outerPolygonGeoJSON);
          })
        );
      } else {
        setInnerPolygons((prev) => prev.filter((_, i) => i !== index));
      }
    }
  };

  const handleMarkerDragEnd = useCallback((event) => {
    setCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  }, []);

  useEffect(() => {
    const calculateArea = () => {
      const outerArea = outerPolygons.reduce((total, path) => {
        const polygonPath = path.map((coord) => [coord.lng, coord.lat]);
        const polygonGeoJSON = turf.polygon([polygonPath]);
        return total + turf.area(polygonGeoJSON);
      }, 0);

      const innerArea = innerPolygons.reduce((total, path) => {
        const polygonPath = path.map((coord) => [coord.lng, coord.lat]);
        const polygonGeoJSON = turf.polygon([polygonPath]);
        return total + turf.area(polygonGeoJSON);
      }, 0);

      const netAreaSqMeters = outerArea - innerArea;
      const netAreaSqFeet = netAreaSqMeters * 10.7639;

      setState({ mapArea: [netAreaSqMeters, netAreaSqFeet], outerCoords: outerPolygons, innerCoords: innerPolygons });
    };
    calculateArea();
  }, [outerPolygons, innerPolygons]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setOuterPolygons([...outerPolygons]);
        setInnerPolygons([...innerPolygons]);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [outerPolygons, innerPolygons]);
  const handleClickAction = (newAction) => {
    if (newAction === action) {
      setAction(null);
    } else {
      setAction(newAction);
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && drawingManager) {
        if (drawingManager.getDrawingMode() !== null) {
          setAction(null);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [drawingManager]);
  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      if (isLoaded) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isFullScreen]);

  return (
    <>
      <div style={mapContainerStyle} className="relative w-full">
        {center && (
          <div style={{ height: "100%", width: "100%" }}>
            <CustomPopup open={error} setOpen={setError} />
            <GoogleMap
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                // cursor: isEraseMode ? "pointer" : "crosshair",
                borderRadius: isFullScreen ? "0px" : "20px",
              }}
              options={mapOptions}
              center={center}
              zoom={zoom}
              mapTypeId="satellite"
              onLoad={handleMapLoad}
            >
              <div className="group">
                <svg
                  style={{
                    borderRadius: "999999",
                    position: "absolute",
                    bottom: "210px",
                    left: "20px",
                    zIndex: 1000,
                    height: "45px",
                    width: "45px",
                    cursor: "pointer",
                  }}
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-5 h-5 rounded-full duration-200 ${action === "pin" ? "bg-[#FFE8C2]" : "bg-white hover:bg-[#DBE1FF]"}`}
                  onClick={() => handleClickAction("pin")}
                >
                  <rect width="45" height="45" rx="22.5" />
                  <path
                    d="M31.3491 20.8767C31.304 20.7213 31.1616 20.6146 31.0001 20.6146H27.6957C28.174 19.5452 29.2181 18.3345 29.2181 16.2145C29.2181 12.7878 26.4286 10 23 10C20.8054 10 18.8182 11.1222 17.6843 13.0018C15.4028 16.7829 18.1037 19.9584 18.3045 20.6146H14.9999C14.8384 20.6146 14.696 20.7213 14.6509 20.8767L11.0145 33.3785C10.9825 33.4883 11.0042 33.6069 11.0727 33.6985C11.1412 33.7898 11.2492 33.8437 11.3635 33.8437H34.6365C34.7508 33.8437 34.8588 33.7898 34.9273 33.6985C34.9958 33.6069 35.0175 33.4883 34.9855 33.3785L31.3491 20.8767ZM18.3068 13.3775C19.3079 11.718 21.0625 10.7273 23 10.7273C26.0277 10.7273 28.4908 13.1888 28.4908 16.2145C28.4908 17.9014 27.7891 18.8902 26.7692 20.8085L23 27.9552C17.5275 17.5835 18.7573 19.9142 18.1509 18.765C17.2475 17.0512 17.3057 15.0371 18.3068 13.3775ZM22.6783 28.9044C22.7411 29.0238 22.8651 29.0983 23 29.0983C23.1349 29.0983 23.2589 29.0238 23.3217 28.9044L27.312 21.3419H30.727L32.128 26.1581L21.6467 32.9574L15.5992 21.3419H18.6883L22.6783 28.9044ZM28.7529 29.2143V33.1165H22.7375L28.7529 29.2143ZM15.0961 21.9502L17.9283 27.3901L13.1172 28.7529L15.0961 21.9502ZM12.8777 29.5765L18.2712 28.0487L20.9097 33.1165H11.8479L12.8777 29.5765ZM29.4802 33.1165V28.7425L32.3401 26.8872L34.1521 33.1165H29.4802Z"
                    fill="black"
                    stroke="black"
                    stroke-width="0.1"
                  />
                  <path
                    d="M22.9998 21.0251C25.568 21.0251 27.6571 18.9358 27.6571 16.3678C27.6571 13.7997 25.568 11.7106 22.9998 11.7106C20.4319 11.7106 18.3428 13.7997 18.3428 16.3678C18.3428 18.9358 20.4319 21.0251 22.9998 21.0251ZM22.9998 12.4379C25.1667 12.4379 26.9298 14.2008 26.9298 16.3678C26.9298 18.5347 25.1667 20.2977 22.9998 20.2977C20.8328 20.2977 19.0701 18.5347 19.0701 16.3678C19.0701 14.2008 20.8328 12.4379 22.9998 12.4379Z"
                    fill="black"
                    stroke="black"
                    stroke-width="0.1"
                  />
                  <path
                    d="M20.5938 19.1338C20.5938 19.3346 20.7564 19.4974 20.9574 19.4974H22.0934H23.9059H25.0419C25.2429 19.4974 25.4056 19.3346 25.4056 19.1338V15.9999H25.7795C25.9368 15.9999 26.0764 15.8987 26.125 15.7491C26.1741 15.5996 26.1211 15.4358 25.9944 15.3427L23.2145 13.3083C23.0867 13.2149 22.9127 13.2149 22.7848 13.3083L20.005 15.3427C19.8782 15.4358 19.8253 15.5996 19.8743 15.7491C19.9229 15.8987 20.0625 15.9999 20.2198 15.9999H20.5938V19.1338ZM22.457 18.7701V17.6062H23.5423V18.7701H22.457ZM24.6783 18.7701H24.2696V17.2426C24.2696 17.0418 24.1069 16.879 23.9059 16.879H22.0934C21.8924 16.879 21.7298 17.0418 21.7298 17.2426V18.7701H21.321V15.9999H24.6783V18.7701ZM22.9997 14.0524L24.6669 15.2726H21.3324L22.9997 14.0524Z"
                    fill="black"
                    stroke="black"
                    stroke-width="0.1"
                  />
                </svg>
                <div
                  style={{ bottom: "220px", left: "80px" }}
                  class="transition transform  lg:group-hover:translate-y-0 translate-y-8 ease-in-out 
              hidden absolute lg:group-hover:flex
               -mt-24 bg-white rounded-md
              text-white  w-[390px]"
                >
                  <div className="flex p-2 space-x-3">
                    <span className="bg-[#D8E6D3] rounded-md">
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-24 h-24 rounded-full duration-200"
                        onClick={() => handleClickAction("pin")}
                      >
                        <rect width="45" height="45" rx="22.5" />
                        <path
                          d="M31.3491 20.8767C31.304 20.7213 31.1616 20.6146 31.0001 20.6146H27.6957C28.174 19.5452 29.2181 18.3345 29.2181 16.2145C29.2181 12.7878 26.4286 10 23 10C20.8054 10 18.8182 11.1222 17.6843 13.0018C15.4028 16.7829 18.1037 19.9584 18.3045 20.6146H14.9999C14.8384 20.6146 14.696 20.7213 14.6509 20.8767L11.0145 33.3785C10.9825 33.4883 11.0042 33.6069 11.0727 33.6985C11.1412 33.7898 11.2492 33.8437 11.3635 33.8437H34.6365C34.7508 33.8437 34.8588 33.7898 34.9273 33.6985C34.9958 33.6069 35.0175 33.4883 34.9855 33.3785L31.3491 20.8767ZM18.3068 13.3775C19.3079 11.718 21.0625 10.7273 23 10.7273C26.0277 10.7273 28.4908 13.1888 28.4908 16.2145C28.4908 17.9014 27.7891 18.8902 26.7692 20.8085L23 27.9552C17.5275 17.5835 18.7573 19.9142 18.1509 18.765C17.2475 17.0512 17.3057 15.0371 18.3068 13.3775ZM22.6783 28.9044C22.7411 29.0238 22.8651 29.0983 23 29.0983C23.1349 29.0983 23.2589 29.0238 23.3217 28.9044L27.312 21.3419H30.727L32.128 26.1581L21.6467 32.9574L15.5992 21.3419H18.6883L22.6783 28.9044ZM28.7529 29.2143V33.1165H22.7375L28.7529 29.2143ZM15.0961 21.9502L17.9283 27.3901L13.1172 28.7529L15.0961 21.9502ZM12.8777 29.5765L18.2712 28.0487L20.9097 33.1165H11.8479L12.8777 29.5765ZM29.4802 33.1165V28.7425L32.3401 26.8872L34.1521 33.1165H29.4802Z"
                          fill="black"
                          stroke="black"
                          stroke-width="0.1"
                        />
                        <path
                          d="M22.9998 21.0251C25.568 21.0251 27.6571 18.9358 27.6571 16.3678C27.6571 13.7997 25.568 11.7106 22.9998 11.7106C20.4319 11.7106 18.3428 13.7997 18.3428 16.3678C18.3428 18.9358 20.4319 21.0251 22.9998 21.0251ZM22.9998 12.4379C25.1667 12.4379 26.9298 14.2008 26.9298 16.3678C26.9298 18.5347 25.1667 20.2977 22.9998 20.2977C20.8328 20.2977 19.0701 18.5347 19.0701 16.3678C19.0701 14.2008 20.8328 12.4379 22.9998 12.4379Z"
                          fill="black"
                          stroke="black"
                          stroke-width="0.1"
                        />
                        <path
                          d="M20.5938 19.1338C20.5938 19.3346 20.7564 19.4974 20.9574 19.4974H22.0934H23.9059H25.0419C25.2429 19.4974 25.4056 19.3346 25.4056 19.1338V15.9999H25.7795C25.9368 15.9999 26.0764 15.8987 26.125 15.7491C26.1741 15.5996 26.1211 15.4358 25.9944 15.3427L23.2145 13.3083C23.0867 13.2149 22.9127 13.2149 22.7848 13.3083L20.005 15.3427C19.8782 15.4358 19.8253 15.5996 19.8743 15.7491C19.9229 15.8987 20.0625 15.9999 20.2198 15.9999H20.5938V19.1338ZM22.457 18.7701V17.6062H23.5423V18.7701H22.457ZM24.6783 18.7701H24.2696V17.2426C24.2696 17.0418 24.1069 16.879 23.9059 16.879H22.0934C21.8924 16.879 21.7298 17.0418 21.7298 17.2426V18.7701H21.321V15.9999H24.6783V18.7701ZM22.9997 14.0524L24.6669 15.2726H21.3324L22.9997 14.0524Z"
                          fill="black"
                          stroke="black"
                          stroke-width="0.1"
                        />
                      </svg>
                    </span>
                    <div className="flex flex-col">
                      <span className="text-base font-semibold text-black-900">Locate on Map</span>
                      <p className="text-sm font-normal text-black-900">
                        A tool for pinpointing the building location. Use this tool to move the pin to the correct location of the building with the
                        rooftop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="group">
                <svg
                  style={{
                    position: "absolute",
                    bottom: "150px",
                    left: "20px",
                    height: "45px",
                    width: "45px",
                    zIndex: 1000,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleClickAction("inner");
                  }}
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-5 h-5 rounded-full duration-200 ${action === "inner" ? "bg-[#FFE8C2]" : "bg-white hover:bg-[#DBE1FF]"}`}
                >
                  <rect width="45" height="45" rx="22.5" />
                  <path
                    d="M29.4667 20.9254C29.3252 20.9254 29.1896 20.9816 29.0896 21.0816C28.9895 21.1816 28.9333 21.3173 28.9333 21.4587V24.5414C28.9333 24.6829 28.9895 24.8185 29.0896 24.9185C29.1896 25.0186 29.3252 25.0747 29.4667 25.0747C29.6081 25.0747 29.7438 25.0186 29.8438 24.9185C29.9438 24.8185 30 24.6829 30 24.5414V21.4587C30 21.3173 29.9438 21.1816 29.8438 21.0816C29.7438 20.9816 29.6081 20.9254 29.4667 20.9254Z"
                    fill="black"
                  />
                  <path
                    d="M29.4667 26.7921C29.3252 26.7921 29.1896 26.8482 29.0895 26.9483C28.9895 27.0483 28.9333 27.1839 28.9333 27.3254V28.8667C28.9333 29.1496 28.8209 29.4209 28.6209 29.621C28.4209 29.821 28.1496 29.9334 27.8667 29.9334H26.3253C26.1839 29.9334 26.0482 29.9896 25.9482 30.0896C25.8482 30.1896 25.792 30.3253 25.792 30.4667C25.792 30.6082 25.8482 30.7438 25.9482 30.8438C26.0482 30.9439 26.1839 31.0001 26.3253 31.0001H27.8667C28.4325 31.0001 28.9751 30.7753 29.3752 30.3752C29.7752 29.9751 30 29.4325 30 28.8667V27.3254C30 27.1839 29.9438 27.0483 29.8438 26.9483C29.7438 26.8482 29.6081 26.7921 29.4667 26.7921Z"
                    fill="black"
                  />
                  <path
                    d="M23.5415 29.9334H20.4589C20.3174 29.9334 20.1818 29.9896 20.0817 30.0896C19.9817 30.1896 19.9255 30.3253 19.9255 30.4667C19.9255 30.6082 19.9817 30.7438 20.0817 30.8439C20.1818 30.9439 20.3174 31.0001 20.4589 31.0001H23.5415C23.683 31.0001 23.8186 30.9439 23.9187 30.8439C24.0187 30.7438 24.0749 30.6082 24.0749 30.4667C24.0749 30.3253 24.0187 30.1896 23.9187 30.0896C23.8186 29.9896 23.683 29.9334 23.5415 29.9334Z"
                    fill="black"
                  />
                  <path
                    d="M17.6747 29.9334H16.1333C15.8504 29.9334 15.5791 29.821 15.3791 29.621C15.179 29.4209 15.0667 29.1496 15.0667 28.8667V27.3254C15.0667 27.1839 15.0105 27.0483 14.9105 26.9483C14.8104 26.8482 14.6748 26.7921 14.5333 26.7921C14.3919 26.7921 14.2562 26.8482 14.1562 26.9483C14.0562 27.0483 14 27.1839 14 27.3254V28.8667C14 29.4325 14.2248 29.9751 14.6248 30.3752C15.0249 30.7753 15.5675 31.0001 16.1333 31.0001H17.6747C17.8161 31.0001 17.9518 30.9439 18.0518 30.8438C18.1518 30.7438 18.208 30.6082 18.208 30.4667C18.208 30.3253 18.1518 30.1896 18.0518 30.0896C17.9518 29.9896 17.8161 29.9334 17.6747 29.9334Z"
                    fill="black"
                  />
                  <path
                    d="M14.5333 25.0747C14.6748 25.0747 14.8104 25.0186 14.9105 24.9185C15.0105 24.8185 15.0667 24.6829 15.0667 24.5414V21.4587C15.0667 21.3173 15.0105 21.1816 14.9105 21.0816C14.8104 20.9816 14.6748 20.9254 14.5333 20.9254C14.3919 20.9254 14.2562 20.9816 14.1562 21.0816C14.0562 21.1816 14 21.3173 14 21.4587V24.5414C14 24.6829 14.0562 24.8185 14.1562 24.9185C14.2562 25.0186 14.3919 25.0747 14.5333 25.0747Z"
                    fill="black"
                  />
                  <path
                    d="M14.5333 19.208C14.6748 19.208 14.8104 19.1518 14.9105 19.0518C15.0105 18.9518 15.0667 18.8161 15.0667 18.6747V17.1333C15.0667 16.8504 15.179 16.5791 15.3791 16.3791C15.5791 16.179 15.8504 16.0667 16.1333 16.0667H17.6747C17.8161 16.0667 17.9518 16.0105 18.0518 15.9105C18.1518 15.8104 18.208 15.6748 18.208 15.5333C18.208 15.3919 18.1518 15.2562 18.0518 15.1562C17.9518 15.0562 17.8161 15 17.6747 15H16.1333C15.5675 15 15.0249 15.2248 14.6248 15.6248C14.2248 16.0249 14 16.5675 14 17.1333V18.6747C14 18.8161 14.0562 18.9518 14.1562 19.0518C14.2562 19.1518 14.3919 19.208 14.5333 19.208Z"
                    fill="black"
                  />
                  <path
                    d="M20.4589 16.0667H23.5415C23.683 16.0667 23.8186 16.0105 23.9187 15.9105C24.0187 15.8104 24.0749 15.6748 24.0749 15.5333C24.0749 15.3919 24.0187 15.2562 23.9187 15.1562C23.8186 15.0562 23.683 15 23.5415 15H20.4589C20.3174 15 20.1818 15.0562 20.0817 15.1562C19.9817 15.2562 19.9255 15.3919 19.9255 15.5333C19.9255 15.6748 19.9817 15.8104 20.0817 15.9105C20.1818 16.0105 20.3174 16.0667 20.4589 16.0667Z"
                    fill="black"
                  />
                  <path
                    d="M26.2665 23C26.2665 22.8586 26.2103 22.7229 26.1103 22.6229C26.0103 22.5229 25.8746 22.4667 25.7332 22.4667H18.2665C18.125 22.4667 17.9894 22.5229 17.8894 22.6229C17.7893 22.7229 17.7332 22.8586 17.7332 23C17.7332 23.1415 17.7893 23.2771 17.8894 23.3772C17.9894 23.4772 18.125 23.5334 18.2665 23.5334H25.7332C25.8746 23.5334 26.0103 23.4772 26.1103 23.3772C26.2103 23.2771 26.2665 23.1415 26.2665 23Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M36.1781 13.0001C36.1058 13.0017 36.037 13.0311 35.9858 13.0822L30.8726 18.1958L29.8625 19.2061C29.8579 19.21 29.8535 19.2141 29.8493 19.2184C29.8448 19.2228 29.8405 19.2274 29.8364 19.2321L29.5939 19.4747C29.5508 19.5176 29.5227 19.5733 29.5139 19.6334L29.2717 21.3306L29.0822 21.5198C29.0296 21.5725 29 21.644 29 21.7185C29 21.793 29.0296 21.8645 29.0822 21.9172C29.1083 21.9435 29.1394 21.9643 29.1736 21.9785C29.2078 21.9927 29.2444 22 29.2815 22C29.3185 22 29.3551 21.9927 29.3893 21.9785C29.4235 21.9643 29.4546 21.9435 29.4807 21.9172L29.6691 21.7288L31.3667 21.4855C31.4268 21.477 31.4826 21.4494 31.5257 21.4066L32.7986 20.1333C32.8006 20.1315 32.8025 20.1297 32.8044 20.1278L37.9177 15.0142C37.9704 14.9614 38 14.8899 38 14.8153C38 14.7407 37.9704 14.6692 37.9177 14.6165L36.3835 13.0822C36.3566 13.0553 36.3245 13.0342 36.2892 13.0201C36.2539 13.006 36.2161 12.9992 36.1781 13.0001ZM36.1841 13.679L37.3209 14.8148L32.6056 19.5312L31.4688 18.3944L36.1841 13.679ZM31.0712 18.7921L32.2076 19.9292L31.5825 20.5544L30.4458 19.4175L31.0712 18.7921ZM30.0553 19.8229L31.178 20.9449L29.8691 21.1314L30.0553 19.8229Z"
                    fill="black"
                    stroke="black"
                    stroke-width="0.2"
                  />
                </svg>
                <div
                  style={{ bottom: "160px", left: "80px" }}
                  class="transition transform  lg:group-hover:translate-y-0 translate-y-8 ease-in-out 
              hidden absolute lg:group-hover:flex
               -mt-24 bg-white rounded-md
              text-white  w-[390px]"
                >
                  <div className="flex p-2 space-x-3">
                    <span className="bg-[#D8E6D3] rounded-md">
                      <svg
                        onClick={() => handleClickAction("inner")}
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-24 h-24 rounded-full duration-200"
                      >
                        <rect width="45" height="45" rx="22.5" />
                        <path
                          d="M29.4667 20.9254C29.3252 20.9254 29.1896 20.9816 29.0896 21.0816C28.9895 21.1816 28.9333 21.3173 28.9333 21.4587V24.5414C28.9333 24.6829 28.9895 24.8185 29.0896 24.9185C29.1896 25.0186 29.3252 25.0747 29.4667 25.0747C29.6081 25.0747 29.7438 25.0186 29.8438 24.9185C29.9438 24.8185 30 24.6829 30 24.5414V21.4587C30 21.3173 29.9438 21.1816 29.8438 21.0816C29.7438 20.9816 29.6081 20.9254 29.4667 20.9254Z"
                          fill="black"
                        />
                        <path
                          d="M29.4667 26.7921C29.3252 26.7921 29.1896 26.8482 29.0895 26.9483C28.9895 27.0483 28.9333 27.1839 28.9333 27.3254V28.8667C28.9333 29.1496 28.8209 29.4209 28.6209 29.621C28.4209 29.821 28.1496 29.9334 27.8667 29.9334H26.3253C26.1839 29.9334 26.0482 29.9896 25.9482 30.0896C25.8482 30.1896 25.792 30.3253 25.792 30.4667C25.792 30.6082 25.8482 30.7438 25.9482 30.8438C26.0482 30.9439 26.1839 31.0001 26.3253 31.0001H27.8667C28.4325 31.0001 28.9751 30.7753 29.3752 30.3752C29.7752 29.9751 30 29.4325 30 28.8667V27.3254C30 27.1839 29.9438 27.0483 29.8438 26.9483C29.7438 26.8482 29.6081 26.7921 29.4667 26.7921Z"
                          fill="black"
                        />
                        <path
                          d="M23.5415 29.9334H20.4589C20.3174 29.9334 20.1818 29.9896 20.0817 30.0896C19.9817 30.1896 19.9255 30.3253 19.9255 30.4667C19.9255 30.6082 19.9817 30.7438 20.0817 30.8439C20.1818 30.9439 20.3174 31.0001 20.4589 31.0001H23.5415C23.683 31.0001 23.8186 30.9439 23.9187 30.8439C24.0187 30.7438 24.0749 30.6082 24.0749 30.4667C24.0749 30.3253 24.0187 30.1896 23.9187 30.0896C23.8186 29.9896 23.683 29.9334 23.5415 29.9334Z"
                          fill="black"
                        />
                        <path
                          d="M17.6747 29.9334H16.1333C15.8504 29.9334 15.5791 29.821 15.3791 29.621C15.179 29.4209 15.0667 29.1496 15.0667 28.8667V27.3254C15.0667 27.1839 15.0105 27.0483 14.9105 26.9483C14.8104 26.8482 14.6748 26.7921 14.5333 26.7921C14.3919 26.7921 14.2562 26.8482 14.1562 26.9483C14.0562 27.0483 14 27.1839 14 27.3254V28.8667C14 29.4325 14.2248 29.9751 14.6248 30.3752C15.0249 30.7753 15.5675 31.0001 16.1333 31.0001H17.6747C17.8161 31.0001 17.9518 30.9439 18.0518 30.8438C18.1518 30.7438 18.208 30.6082 18.208 30.4667C18.208 30.3253 18.1518 30.1896 18.0518 30.0896C17.9518 29.9896 17.8161 29.9334 17.6747 29.9334Z"
                          fill="black"
                        />
                        <path
                          d="M14.5333 25.0747C14.6748 25.0747 14.8104 25.0186 14.9105 24.9185C15.0105 24.8185 15.0667 24.6829 15.0667 24.5414V21.4587C15.0667 21.3173 15.0105 21.1816 14.9105 21.0816C14.8104 20.9816 14.6748 20.9254 14.5333 20.9254C14.3919 20.9254 14.2562 20.9816 14.1562 21.0816C14.0562 21.1816 14 21.3173 14 21.4587V24.5414C14 24.6829 14.0562 24.8185 14.1562 24.9185C14.2562 25.0186 14.3919 25.0747 14.5333 25.0747Z"
                          fill="black"
                        />
                        <path
                          d="M14.5333 19.208C14.6748 19.208 14.8104 19.1518 14.9105 19.0518C15.0105 18.9518 15.0667 18.8161 15.0667 18.6747V17.1333C15.0667 16.8504 15.179 16.5791 15.3791 16.3791C15.5791 16.179 15.8504 16.0667 16.1333 16.0667H17.6747C17.8161 16.0667 17.9518 16.0105 18.0518 15.9105C18.1518 15.8104 18.208 15.6748 18.208 15.5333C18.208 15.3919 18.1518 15.2562 18.0518 15.1562C17.9518 15.0562 17.8161 15 17.6747 15H16.1333C15.5675 15 15.0249 15.2248 14.6248 15.6248C14.2248 16.0249 14 16.5675 14 17.1333V18.6747C14 18.8161 14.0562 18.9518 14.1562 19.0518C14.2562 19.1518 14.3919 19.208 14.5333 19.208Z"
                          fill="black"
                        />
                        <path
                          d="M20.4589 16.0667H23.5415C23.683 16.0667 23.8186 16.0105 23.9187 15.9105C24.0187 15.8104 24.0749 15.6748 24.0749 15.5333C24.0749 15.3919 24.0187 15.2562 23.9187 15.1562C23.8186 15.0562 23.683 15 23.5415 15H20.4589C20.3174 15 20.1818 15.0562 20.0817 15.1562C19.9817 15.2562 19.9255 15.3919 19.9255 15.5333C19.9255 15.6748 19.9817 15.8104 20.0817 15.9105C20.1818 16.0105 20.3174 16.0667 20.4589 16.0667Z"
                          fill="black"
                        />
                        <path
                          d="M26.2665 23C26.2665 22.8586 26.2103 22.7229 26.1103 22.6229C26.0103 22.5229 25.8746 22.4667 25.7332 22.4667H18.2665C18.125 22.4667 17.9894 22.5229 17.8894 22.6229C17.7893 22.7229 17.7332 22.8586 17.7332 23C17.7332 23.1415 17.7893 23.2771 17.8894 23.3772C17.9894 23.4772 18.125 23.5334 18.2665 23.5334H25.7332C25.8746 23.5334 26.0103 23.4772 26.1103 23.3772C26.2103 23.2771 26.2665 23.1415 26.2665 23Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M36.1781 13.0001C36.1058 13.0017 36.037 13.0311 35.9858 13.0822L30.8726 18.1958L29.8625 19.2061C29.8579 19.21 29.8535 19.2141 29.8493 19.2184C29.8448 19.2228 29.8405 19.2274 29.8364 19.2321L29.5939 19.4747C29.5508 19.5176 29.5227 19.5733 29.5139 19.6334L29.2717 21.3306L29.0822 21.5198C29.0296 21.5725 29 21.644 29 21.7185C29 21.793 29.0296 21.8645 29.0822 21.9172C29.1083 21.9435 29.1394 21.9643 29.1736 21.9785C29.2078 21.9927 29.2444 22 29.2815 22C29.3185 22 29.3551 21.9927 29.3893 21.9785C29.4235 21.9643 29.4546 21.9435 29.4807 21.9172L29.6691 21.7288L31.3667 21.4855C31.4268 21.477 31.4826 21.4494 31.5257 21.4066L32.7986 20.1333C32.8006 20.1315 32.8025 20.1297 32.8044 20.1278L37.9177 15.0142C37.9704 14.9614 38 14.8899 38 14.8153C38 14.7407 37.9704 14.6692 37.9177 14.6165L36.3835 13.0822C36.3566 13.0553 36.3245 13.0342 36.2892 13.0201C36.2539 13.006 36.2161 12.9992 36.1781 13.0001ZM36.1841 13.679L37.3209 14.8148L32.6056 19.5312L31.4688 18.3944L36.1841 13.679ZM31.0712 18.7921L32.2076 19.9292L31.5825 20.5544L30.4458 19.4175L31.0712 18.7921ZM30.0553 19.8229L31.178 20.9449L29.8691 21.1314L30.0553 19.8229Z"
                          fill="black"
                          stroke="black"
                          stroke-width="0.2"
                        />
                      </svg>
                    </span>
                    <div className="flex flex-col">
                      <span className="text-base font-semibold text-black-900">Remove Obstacle</span>
                      <p className="text-sm font-normal text-black-900">
                        A tool for marking obstacles within a drawn rooftop area. Use this tool to outline obstacles that fall within the marked
                        rooftop space.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="group">
                <svg
                  style={{
                    position: "absolute",
                    bottom: "90px",
                    left: "20px",
                    zIndex: 1000,
                    height: "45px",
                    width: "45px",
                    cursor: "pointer",
                  }}
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-5 h-5 rounded-full duration-200 ${action === "outer" ? "bg-[#FFE8C2]" : "bg-white hover:bg-[#DBE1FF]"}`}
                  onClick={() => handleClickAction("outer")}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.5464 12.0002C29.3698 12.0042 29.2015 12.0747 29.0765 12.1973L16.5774 24.47L14.1082 26.8945C14.0972 26.9041 14.0864 26.914 14.076 26.9242C14.0651 26.9348 14.0546 26.9458 14.0445 26.9571L13.4517 27.5392C13.3463 27.6421 13.2778 27.7758 13.2563 27.9202L12.6642 31.9933L12.201 32.4475C12.0723 32.5741 12 32.7456 12 32.9244C12 33.1033 12.0723 33.2748 12.201 33.4013C12.2648 33.4643 12.3407 33.5143 12.4243 33.5484C12.5079 33.5824 12.5975 33.6 12.688 33.6C12.7785 33.6 12.8681 33.5824 12.9517 33.5484C13.0353 33.5143 13.1112 33.4643 13.1751 33.4013L13.6356 32.9491L17.7852 32.3651C17.9322 32.3449 18.0685 32.2785 18.1739 32.1759L21.2856 29.1199C21.2903 29.1156 21.295 29.1112 21.2997 29.1068L33.7987 16.834C33.9276 16.7074 34 16.5358 34 16.3568C34 16.1778 33.9276 16.0061 33.7987 15.8795L30.0486 12.1973C29.9828 12.1328 29.9044 12.0821 29.8181 12.0482C29.7318 12.0143 29.6394 11.998 29.5464 12.0002ZM29.5612 13.6297L32.3399 16.3554L20.8136 27.675L18.0349 24.9466L29.5612 13.6297ZM17.0628 25.9011L19.8408 28.6302L18.3129 30.1305L15.5342 27.4021L17.0628 25.9011ZM14.5795 28.3751L17.324 31.0678L14.1244 31.5154L14.5795 28.3751Z"
                    fill="black"
                  />
                </svg>
                <div
                  style={{ bottom: "100px", left: "80px" }}
                  class="transition transform  lg:group-hover:translate-y-0 translate-y-8 ease-in-out 
              hidden absolute lg:group-hover:flex
               -mt-24 bg-white rounded-md
              text-white  w-[390px]"
                >
                  <div className="flex p-2 space-x-3">
                    <span className="bg-[#D8E6D3] rounded-md">
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-24 h-24 rounded-full duration-200"
                        onClick={() => handleClickAction("outer")}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M29.5464 12.0002C29.3698 12.0042 29.2015 12.0747 29.0765 12.1973L16.5774 24.47L14.1082 26.8945C14.0972 26.9041 14.0864 26.914 14.076 26.9242C14.0651 26.9348 14.0546 26.9458 14.0445 26.9571L13.4517 27.5392C13.3463 27.6421 13.2778 27.7758 13.2563 27.9202L12.6642 31.9933L12.201 32.4475C12.0723 32.5741 12 32.7456 12 32.9244C12 33.1033 12.0723 33.2748 12.201 33.4013C12.2648 33.4643 12.3407 33.5143 12.4243 33.5484C12.5079 33.5824 12.5975 33.6 12.688 33.6C12.7785 33.6 12.8681 33.5824 12.9517 33.5484C13.0353 33.5143 13.1112 33.4643 13.1751 33.4013L13.6356 32.9491L17.7852 32.3651C17.9322 32.3449 18.0685 32.2785 18.1739 32.1759L21.2856 29.1199C21.2903 29.1156 21.295 29.1112 21.2997 29.1068L33.7987 16.834C33.9276 16.7074 34 16.5358 34 16.3568C34 16.1778 33.9276 16.0061 33.7987 15.8795L30.0486 12.1973C29.9828 12.1328 29.9044 12.0821 29.8181 12.0482C29.7318 12.0143 29.6394 11.998 29.5464 12.0002ZM29.5612 13.6297L32.3399 16.3554L20.8136 27.675L18.0349 24.9466L29.5612 13.6297ZM17.0628 25.9011L19.8408 28.6302L18.3129 30.1305L15.5342 27.4021L17.0628 25.9011ZM14.5795 28.3751L17.324 31.0678L14.1244 31.5154L14.5795 28.3751Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="flex flex-col">
                      <span className="text-base font-semibold text-black-900">Draw Tool</span>
                      <p className="text-sm font-normal text-black-900">
                        A tool for drawing on maps to calculate rooftop area. Easily outline rooftops directly on the map to determine their area.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="group">
                <svg
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    left: "20px",
                    height: "45px",
                    width: "45px",
                    zIndex: 1000,
                    cursor: "pointer",
                  }}
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-5 h-5 rounded-full duration-200 ${action === "erase" ? "bg-[#FFE8C2]" : "bg-white hover:bg-[#DBE1FF]"}`}
                  onClick={() => handleClickAction("erase")}
                >
                  <path
                    d="M33.2915 30.5774C33.6828 30.5774 34 30.8959 34 31.2887C34 31.6815 33.6828 32 33.2915 32H26.3246C25.9333 32 25.6161 31.6815 25.6161 31.2887C25.6161 30.8959 25.9333 30.5774 26.3246 30.5774H33.2915ZM27.0529 16.0661L30.3127 19.3468C31.8006 20.7456 31.8313 23.1168 30.4524 24.6307L30.4099 24.6754L23.7562 31.3716C23.4017 31.7284 22.9204 31.9289 22.4185 31.9289H17.7359C17.234 31.9289 16.7527 31.7284 16.3982 31.3716L14.1551 29.1141C13.3992 28.4493 13 27.4536 13 26.4206C13 25.3876 13.3992 24.392 14.1551 23.7271L21.7674 16.0661C23.1798 14.6446 25.6404 14.6446 27.0529 16.0661ZM17.1275 22.7498L15.1197 24.7707C14.6513 25.1635 14.4171 25.7921 14.4171 26.4206C14.4171 27.0492 14.6513 27.6778 15.1197 28.0706L17.2631 30.2277C17.4403 30.406 17.6809 30.5063 17.9319 30.5063H22.2225C22.4734 30.5063 22.7141 30.406 22.8913 30.2277L23.74 29.3729L17.1275 22.7498ZM22.7706 17.0708L18.1279 21.7426L24.741 28.3657L29.4066 23.6707C30.2654 22.7278 30.2654 21.235 29.3286 20.3707L26.0496 17.0708C25.1908 16.2065 23.6294 16.2065 22.7706 17.0708Z"
                    fill="black"
                  />
                </svg>
                <div
                  style={{ bottom: "50px", left: "80px" }}
                  class="transition transform  lg:group-hover:translate-y-0 translate-y-8 ease-in-out 
              hidden absolute lg:group-hover:flex
               -mt-24 bg-white rounded-md
              text-white  w-[390px]"
                >
                  <div className="flex p-2 space-x-3">
                    <span className="bg-[#D8E6D3] rounded-md">
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-24 h-24 rounded-full duration-200"
                        onClick={() => handleClickAction("erase")}
                      >
                        <path
                          d="M33.2915 30.5774C33.6828 30.5774 34 30.8959 34 31.2887C34 31.6815 33.6828 32 33.2915 32H26.3246C25.9333 32 25.6161 31.6815 25.6161 31.2887C25.6161 30.8959 25.9333 30.5774 26.3246 30.5774H33.2915ZM27.0529 16.0661L30.3127 19.3468C31.8006 20.7456 31.8313 23.1168 30.4524 24.6307L30.4099 24.6754L23.7562 31.3716C23.4017 31.7284 22.9204 31.9289 22.4185 31.9289H17.7359C17.234 31.9289 16.7527 31.7284 16.3982 31.3716L14.1551 29.1141C13.3992 28.4493 13 27.4536 13 26.4206C13 25.3876 13.3992 24.392 14.1551 23.7271L21.7674 16.0661C23.1798 14.6446 25.6404 14.6446 27.0529 16.0661ZM17.1275 22.7498L15.1197 24.7707C14.6513 25.1635 14.4171 25.7921 14.4171 26.4206C14.4171 27.0492 14.6513 27.6778 15.1197 28.0706L17.2631 30.2277C17.4403 30.406 17.6809 30.5063 17.9319 30.5063H22.2225C22.4734 30.5063 22.7141 30.406 22.8913 30.2277L23.74 29.3729L17.1275 22.7498ZM22.7706 17.0708L18.1279 21.7426L24.741 28.3657L29.4066 23.6707C30.2654 22.7278 30.2654 21.235 29.3286 20.3707L26.0496 17.0708C25.1908 16.2065 23.6294 16.2065 22.7706 17.0708Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="flex flex-col">
                      <span className="text-base font-semibold text-black-900">Erase Tool</span>
                      <p className="text-sm font-normal text-black-900">
                        A tool for erasing marked rooftop areas or obstacles. Use this tool to remove any outlines or markings from the map.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {isFullScreen && (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    left: "0",
                    zIndex: 1000,
                    height: "88px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <div className="flex items-center space-x-5 px-12 pt-3">
                    <CustomInputSelect2
                      disabled={true}
                      required
                      type="number"
                      label="Rooftop Area"
                      inputName="rooftopArea"
                      selectName="rooftopAreaUnit"
                      register={register}
                      control={control}
                      options={options}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                    />
                    <button
                      onClick={() => setIsFullScreen(false)}
                      style={{ marginTop: "1.5rem" }}
                      type="button"
                      className="tracking-wide font-medium bg-white border border-primary text-primary py-1.5 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                    >
                      <span className="text-sm font-medium text-primary">Submit</span>
                    </button>
                  </div>
                </div>
              )}
              <img
                src={plus}
                style={{
                  position: "absolute",
                  right: "20px",
                  bottom: "30px",
                  zIndex: 1000,
                  height: "30px",
                  width: "30px",
                  cursor: zoom < 20 ? "pointer" : "not-allowed",
                }}
                alt="drawicon"
                onClick={increaseZoom}
              />
              <img
                src={minus}
                style={{
                  position: "absolute",
                  right: "60px",
                  bottom: "30px",
                  height: "30px",
                  width: "30px",
                  zIndex: 1000,
                  cursor: zoom >= 1 ? "pointer" : "not-allowed",
                }}
                alt="eraseicon"
                onClick={decreseZoom}
              />
              <img
                src={isFullScreen ? notfullscreen : fullscreen}
                style={{ position: "absolute", top: isFullScreen ? "105px" : "20px", right: "20px", zIndex: 1000, cursor: "pointer" }}
                alt="eraseicon"
                onClick={handleFullScreenToggle}
              />
              <>
                {(action === "outer" || action === "inner") && (
                  <DrawingManager
                    onLoad={handleDrawingManagerLoad}
                    onOverlayComplete={handleOverlayComplete}
                    options={{
                      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                      drawingControl: false,
                      drawingControlOptions: {
                        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                      },
                      polygonOptions: {
                        fillColor: action === "outer" ? "#FFEF00" : "#FF0000",
                        fillOpacity: 0.35,
                        strokeColor: action === "outer" ? "#FFEF00" : "#FF0000",
                        strokeOpacity: 1,
                        strokeWeight: 3,
                      },
                    }}
                  />
                )}
                {outerPolygons.map((path, index) => (
                  <Polygon
                    key={index}
                    path={path}
                    options={{
                      fillColor: "#FFEF00",
                      fillOpacity: 0.35,
                      strokeColor: "#FFEF00",
                      strokeOpacity: 1,
                      strokeWeight: 3,
                      zIndex: 10,
                    }}
                    onClick={() => {
                      handlePolygonClick(index, true);
                    }}
                  />
                ))}
                {innerPolygons.map((path, index) => (
                  <Polygon
                    key={index}
                    path={path}
                    options={{
                      fillColor: "#FF0000",
                      fillOpacity: 0.35,
                      strokeColor: "#FF0000",
                      strokeOpacity: 1,
                      strokeWeight: 3,
                      zIndex: 100,
                    }}
                    onClick={() => {
                      handlePolygonClick(index, false);
                    }}
                  />
                ))}
                <Marker position={center} draggable={action === "pin"} onDragEnd={handleMarkerDragEnd} />
              </>
            </GoogleMap>
          </div>
        )}
      </div>
    </>
  );
};

export default MapComponent3;
