/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { Tabs, TabsHeader, Tab } from "@material-tailwind/react";
import CustomInput from "../../components/CustomInput";
import Box from "@mui/material/Box";
import { TfiClose } from "react-icons/tfi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { buySellDetails } from "../../validation/buySellValidations";
import { toast } from "react-toastify";
import buySell from "../../services/buySellService";
import { Modal } from "@mui/material";
import moment from "moment";
import AddFaqModal from "../AddFaqModal";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsExclamationDiamond } from "react-icons/bs";
const MainLayout = ({
  children,
  tabs = [],
  activeTab = "",
  setActiveTab = () => "",
  navigateUrl = "",
  navigateButton = "",
  CustomIcon = "",
  id = "",
  getProjectDetails,
  projectData = null,
  changeRequest = null,
  buySellStep = null,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [validityBarMessage, setValidityBarMessage] = useState("");
  const [addFaqModal, setAddFaqModal] = useState(false);
  const handleClose = () => {
    setOpen(false);
    clearErrors("extendBidValidity");
  };
  const {
    setValue,
    register,
    watch,
    clearErrors,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(buySellDetails) });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
  };
  const setValidityMessage = () => {
    if (projectData?.is_sold) {
      setValidityBarMessage(`This project is sold on ${moment(projectData?.sold_date)?.format("DD/MM/YYYY, HH:mm")}`);
    } else {
      if (projectData?.MasterProjectStatus?.name === "Published") {
        setValidityBarMessage(`Bidding on the project is open till ${moment(projectData?.bid_expiry_date)?.format("DD/MM/YYYY")}`);
      } else if (projectData?.MasterProjectStatus?.name === "Rejected") {
        setValidityBarMessage(`The project is rejected by the GAED team.`);
      } else if (projectData?.MasterProjectStatus?.name === "Changes Required") {
        setValidityBarMessage(`The project needs changes.`);
      } else if (projectData?.MasterProjectStatus?.name === "Pending") {
        setValidityBarMessage(`The project is currently undergoing review by the GAED team.`);
      }
    }
  };
  const handleFormSubmit = async (data) => {
    let payload = {
      project_id: Number(id),
      extended_bid_expiry_days: Number(data?.extendBidValidity),
    };
    try {
      let response = await buySell.extendBidValidity(payload);
      if (response?.data?.status) {
        toast.success("Bid's Validity Extended Successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
        getProjectDetails();
        handleClose();
        resetField("extendBidValidity");
      }
    } catch (error) {
      console.log("Extend Bid Validity Error", error);
    }
  };
  useEffect(() => {
    if (projectData) {
      setValidityMessage();
    }
  }, [projectData]);
 
  return (
    <div>
      <Header />
      {location?.pathname === "/add-new-project" && (
        <div className="px-4 xl:px-24 2xl:px-48 pt-4 text-black-900 text-xl font-bold pb-5 border-b border-gray-600">Create New Project</div>
      )}
      {(buySellStep === 0 && changeRequest?.step_one_change) ||
      (buySellStep === 1 && changeRequest?.step_two_change) ||
      (buySellStep === 2 && changeRequest?.step_three_change) ? (
        <div className="bg-[#F7F7F7] border-b border-gray-600 px-4 xl:px-24 2xl:px-48 py-5 sm:sticky sm:top-0 z-30 flex flex-col">
          <div className="flex space-x-2 items-center mb-2">
            <BsExclamationDiamond className="text-red-500 w-5 h-5" />
            <h3 className="text-red-500 font-medium">Actions required..</h3>
          </div>
          <ul className="xs:text-sm sm:text-base text-black-100 font-medium list-disc list-inside xs:ml-2 sm:ml-8 overflow-y-auto max-h-36">
            {buySellStep === 0 &&
              changeRequest?.step_one_change &&
              changeRequest?.step_one_change.split("\n").map((val) => {
                return <li>{val}</li>;
              })}
            {buySellStep === 1 &&
              changeRequest?.step_two_change &&
              changeRequest?.step_two_change.split("\n").map((val) => {
                return <li>{val}</li>;
              })}
            {buySellStep === 2 &&
              changeRequest?.step_three_change &&
              changeRequest?.step_three_change.split("\n").map((val) => {
                return <li>{val}</li>;
              })}
          </ul>
        </div>
      ) : null}
      {changeRequest?.MasterProjectSubStatus?.id === 7 && (
        <div className="bg-[#F7F7F7] border-b border-gray-600 px-4 xl:px-24 2xl:px-48 py-5 sm:sticky sm:top-0 z-30 flex flex-col">
          <div className="flex space-x-2 items-center mb-2">
            <BsExclamationDiamond className="text-red-500 w-5 h-5" />
            <h3 className="text-red-500 font-medium">Actions required..</h3>
          </div>
          <ul className="xs:text-sm sm:text-base text-black-100 font-medium list-disc list-inside xs:ml-2 sm:ml-8 overflow-y-auto max-h-36">
            {changeRequest?.changes_required.split("\n").map((val) => {
              return <li>{val}</li>;
            })}
          </ul>
        </div>
      )}
      {tabs.length > 0 && (
        <div className="flex items-center justify-between">
          <Tabs value={activeTab} className="w-full">
            <TabsHeader
              className="w-full rounded-none bg-green-200 px-4 xl:px-24 2xl:px-48 py-3 xs:block sm:flex justify-between items-center"
              indicatorProps={{
                className: "bg-transparent border-b-4 border-primary shadow-none rounded-none",
              }}
            >
              <div className="border-b-4 border-[#E0E0E0] flex items-center xs:mb-3 sm:mb-0 xs:w-full sm:w-auto">
                {tabs.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => setActiveTab(value)}
                    className={
                      activeTab === value
                        ? "top-1 w-full sm:min-w-48 text-base font-semibold text-black-100 pb-3"
                        : "top-1 w-full sm:min-w-48 text-base font-normal text-black-100 pb-3"
                    }
                  >
                    {label}
                  </Tab>
                ))}
              </div>
              {navigateUrl && (
                <button
                  style={{ border: "1px solid #325B22" }}
                  type="button"
                  className="xs:mx-auto sm:mr-0 sm:ml-auto h-10 font-medium bg-secondary hover:bg-yellow-400 text-black text-sm px-5 py-1.5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                  onClick={() => navigate(navigateUrl)}
                >
                  {/* <FiPlus className="mr-2 w-5 h-5" /> */}
                  <span>{CustomIcon}</span>
                  <span>{navigateButton}</span>
                </button>
              )}
            </TabsHeader>
          </Tabs>
        </div>
      )}
      {projectData && (
        <>
          <div className="flex items-center justify-between bg-[#FFEFD5] px-4 xl:px-24 2xl:px-48 py-3">
            {projectData?.MasterProjectStatus?.id !== 4 ? (
              <>
                <h2 className="text-lg font-semibold text-black-900">{validityBarMessage}</h2>
                {!projectData?.is_sold && (
                  <>
                    {projectData?.MasterProjectStatus?.name === "Published" && (
                      <div className="flex items-center space-x-3">
                        <button
                          onClick={() => {
                            navigate("/deal-room", { state: { id: id } });
                          }}
                          type="button"
                          className="tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        >
                          <span className="text-sm font-medium text-white flex items-center">View Bids</span>
                        </button>
                        <button
                          type="button"
                          className="tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                          onClick={handleOpen}
                          variant="gradient"
                        >
                          <span className="text-sm font-medium text-white flex items-center">Extend Validity</span>
                        </button>
                        <button
                          type="button"
                          onClick={() => setAddFaqModal(true)}
                          className="tracking-wide font-medium hover:bg-primary py-2 px-4 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none text-primary hover:text-white border border-primary"
                        >
                          <span className="text-sm font-medium flex items-center">
                            <FiPlus className="w-4 h-4 mr-2" /> Add FAQs
                          </span>
                        </button>
                        <AddFaqModal addFaqModal={addFaqModal} setAddFaqModal={setAddFaqModal} id={projectData?.id} />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="flex flex-col space-y-3">
                <h2 className="text-lg font-semibold text-black-900">{validityBarMessage}</h2>
                <ul className="list-disc list-inside text-sm font-normal space-y-1">
                  {projectData?.ProjectRejectData?.map((val) => (
                    <li>{val?.reason === 6 ? val?.other_comment : val?.RejectReason?.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      <section className="px-4 xl:px-24 2xl:px-48 pt-4">{children}</section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "98" }}
      >
        <Box sx={style}>
          <div className="flex flex-col space-y-3 w-[400px] m-auto">
            <div className="flex flex-col mb-3">
              <div className="flex justify-between align-center">
                <p className="text-xl">Set Bid's Validity</p>
                <TfiClose className="text-black-900 w-5 h-5 cursor-pointer" onClick={handleClose} />
              </div>
              <div className="bg-green-200 p-4 rounded-lg my-5">
                <div className="flex items-center w-full border-b border-gray-100 pb-2 mb-4 space-x-2">
                  <AiOutlineInfoCircle className="w-5 h-5" />
                  <h2 className="text-black-900 text-base font-semibold">Note</h2>
                </div>
                <ul className="space-y-2 list-disc list-inside pl-2">
                  {(projectData?.extend_expiry_count === 0 || projectData?.extend_expiry_count === 1) && (
                    <li className="font-normal text-xs text-black-100">Validity period must be between 30 to 90 days.</li>
                  )}
                  {projectData?.extend_expiry_count === 0 && (
                    <li className="font-normal text-xs text-black-100">Bid validity may be extended up to two times.</li>
                  )}
                  {projectData?.extend_expiry_count === 1 && (
                    <li className="font-normal text-xs text-red-500">You have one remaining bid validity extension opportunity.</li>
                  )}
                  {projectData?.extend_expiry_count === 2 && (
                    <li className="font-normal text-xs text-red-500">You have consumed two bid validity extension opportunity.</li>
                  )}
                </ul>
              </div>
            </div>
            {projectData?.extend_expiry_count !== 2 && (
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <CustomInput
                  type={"number"}
                  label={"Bid's Validity(in days)"}
                  SideText={"Days"}
                  name="extendBidValidity"
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                />
                <button
                  type="submit"
                  className="tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none w-full text-white mt-3"
                >
                  <span>Done</span>
                </button>
              </form>
            )}
            {projectData?.extend_expiry_count === 2 && (
              <button
                type="button"
                onClick={() => handleClose()}
                className="tracking-wide font-medium bg-primary py-2 px-5 rounded-full transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none w-full text-white mt-3"
              >
                <span>Ok</span>
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <section></section>
    </div>
  );
};
 
export default MainLayout;