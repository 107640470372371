import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "../locales/en.json";
import frTranslations from "../locales/fr.json";
import store from "../state/store";

const updateLanguage = () => {
  const state = store.getState();
  const currentLanguage = state.application?.language;
  i18n.changeLanguage(currentLanguage);
};

store.subscribe(updateLanguage);

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    fr: { translation: frTranslations },
  },
  lng: store.getState()?.application?.language,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
