import { URLS } from "../config/urls";
import http from "../utils/helpers/common.http";

const commonService = {
  updateProfilePhoto: (data) => {
    return http.post(URLS.common.updateProfilePhoto, data);
  },
};

export default commonService;
