import { AppBar, Toolbar, Typography, Button, Slide, Backdrop, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#325B22",
  "&:hover": {
    backgroundColor: "#325B22",
  },
});

const CustomPopup = ({ open = false, setOpen = () => "" }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!!open}>
      <Slide direction="down" in={!!open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: "100%",
            height: "5rem",
            backgroundColor: "#ffffff",
            borderBottom: "1px solid black",
            position: "fixed",
            top: 0,
          }}
        >
          <AppBar
            position="static"
            sx={{
              height: "5rem",
              justifyContent: "center",
              boxShadow: "none",
              backgroundColor: "transparent",
            }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1, color: "black", fontSize: "18px" }}>
                {open}
              </Typography>
              <CustomButton className="map-btn" onClick={handleClose}>
                Okay
              </CustomButton>
            </Toolbar>
          </AppBar>
        </Box>
      </Slide>
    </Backdrop>
  );
};

export default CustomPopup;
