import "./App.css";
import AppRouter from "./routes/AppRouter";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
import { useSelector } from "react-redux";
import Spinner from "./components/Spinner";
import { ToastContainer } from "react-toastify";
import { LoadScript } from "@react-google-maps/api";
import { useEffect } from "react";
import { getAuthTokenCookie, removeAuthTokenCookie } from "./utils/cookieUtils";
const libraries = ["places", "drawing"];
function App() {
  const appState = useSelector((state) => state.application);
  useEffect(() => {
    if (!getAuthTokenCookie()) {
      removeAuthTokenCookie();
    }
  }, []);

  return (
    <div className="App">
      <LoadScript loadingElement={<Spinner />} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries} mapIds={[]}>
        <I18nextProvider i18n={i18n}>
          <AppRouter />
        </I18nextProvider>
      </LoadScript>
      {appState.spinner > 0 && <Spinner />}
      <ToastContainer />
    </div>
  );
}

export default App;
