import { useState } from "react";
import AdminChatModule from "./AdminChatModule";
import { useSelector } from "react-redux";

const Chatbox = () => {
  const storeUserData = useSelector((state) => state?.user?.userData);
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const toggleChatbox = () => setIsChatboxOpen((prev) => !prev);

  return (
    <div className={`fixed bottom-0 right-0 mb-4 mr-4 z-[9999]`}>
      <button id="open-chat" className="z-20" onClick={toggleChatbox}>
        <div class="p-3 rounded-full bg-primary text-white chat-btn">
          {!isChatboxOpen ? (
            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clip-rule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          )}
        </div>
      </button>
      <div>
        {isChatboxOpen && (
          <div id="chat-container" className={`fixed bottom-24 right-4 xsm:w-96 xs:w-80 rounded-2xl`}>
            <AdminChatModule
              sendId={storeUserData?.userId}
              recvId="A"
              // recvEmail={projectData?.vendor?.Vendor?.email}
              name={`Admin  `}
              consumerId={storeUserData?.userId}
              // profileImage={image}
            />
            {/* <div className="bg-green-200 shadow-md rounded-lg max-w-lg w-full">
          <div className="p-5 py-4 bg-[#DEECD9] rounded-t-xl xs:text-sm sm:text-base font-medium flex justify-between items-center">
            <p>Admin Bot</p>
            <button id="close-chat" className="text-black-900 hover:text-primary focus:outline-none" onClick={toggleChatbox}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div id="chatbox" className=" p-4 h-80 overflow-y-auto" ref={chatboxRef}>
            {messages.map((message, index) => (
              <div key={index} className={`mb-2 ${message.type === "user" ? "text-right" : ""}`}>
                <p
                  className={`rounded-lg py-1.5 px-3 inline-block text-sm font-normal break-all ${
                    message.type === "user" ? "bg-primary text-white" : "bg-white text-primary"
                  }`}
                >
                  {message.text}
                </p>
              </div>
            ))}
          </div>
          <div className="p-4 flex">
            <div className="flex flex-1">
              <input
                id="user-input"
                type="text"
                placeholder="Enter your message..."
                className="w-full px-4 py-2 pr-0 rounded-l-full focus:outline-none text-sm"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button id="send-button" className="bg-white text-primary px-3 py-2 rounded-r-full transition duration-300" onClick={handleSendMessage}>
                <MdOutlineSend className="w-6 h-6" />
              </button>
            </div>
            <div className="ml-4">
              <input hidden type="file" id="attachment" />
              <label
                htmlFor="attachment"
                className="cursor-pointer shadow flex items-center justify-center bg-primary hover:bg-white rounded-full text-white hover:text-primary xs:p-3 sm:p-3 flex-shrink-0"
              >
                <ImAttachment className="w-5 h-5" />
              </label>
            </div>
          </div>
        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chatbox;
