import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthTokenCookie } from "../utils/cookieUtils";
import { useSelector } from "react-redux";

function ProtectedRoute({ children, role }) {
  const navigate = useNavigate();
  const userData = getAuthTokenCookie();
  const userRole = useSelector((state) => state?.user?.userData?.loggedIn);

  useEffect(() => {
    if (!userData) {
      navigate("/signup");
    }
  }, [userData, navigate]);

  useEffect(() => {
    if (userData && userRole !== role) {
      navigate("/page-not-found");
    }
  }, [userData, userRole, role, navigate]);
  return userData ? (userRole === role ? children : null) : null;
}

export default ProtectedRoute;
