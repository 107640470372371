import axios from "axios";
import { getAuthTokenCookie, getLoginTokenCookie, removeAuthTokenCookie, setAuthTokenCookie } from "../cookieUtils";
import store from "../../state/store";
import { setSpinner } from "../../state/reducers/applicationSlice";
import { toast } from "react-toastify";
import authService from "../../services/authService";
import adminAuthService from "../../services/adminAuthService";

const http = axios.create();

const MAIN_URL = process.env.REACT_APP_MAIN_URL;

const formDataUrls = ["consumer/kyc-add", "property/media-upload", "/property/edit-media", "project/media-upload"];

let configBoolean = true;

const startSpinner = () => {
  store.dispatch(setSpinner(store.getState().application.spinner + 1));
};

const stopSpinner = () => {
  setTimeout(() => {
    store.dispatch(setSpinner(store.getState().application.spinner - 1));
  }, 100);
};

http.interceptors.request.use(
  (config) => {
    startSpinner();
    config.headers["Content-Type"] = "application/json";
    if (config.url === "user/login" || config.url === "admin/admin_login/login") {
      config.headers["x-gaed-auth"] = getLoginTokenCookie();
    } else if (formDataUrls.includes(config.url)) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (config?.url?.search("download-media") > -1) {
      config.responseType = "arraybuffer";
    } else if (config?.url?.startsWith("http")) {
      // No action needed here.
    } else if (!configBoolean && config?.url?.search("refresh-token") === -1) {
      configBoolean = true;
    } else {
      config.url = `${MAIN_URL}${config.url}`;
    }
    if (getAuthTokenCookie()) {
      config.headers["x-gaed-auth"] = getAuthTokenCookie();
    }
    return config;
  },
  (error) => {
    stopSpinner();
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async (response) => {
    stopSpinner();
    return response;
  },
  async (error) => {
    stopSpinner();
    if (error?.response?.status === 401) {
      try {
        let payload = {
          refreshToken: JSON.parse(localStorage.getItem("userData"))?.refreshToken,
        };
        let res =
          store.getState()?.user?.userData?.loggedIn === "Customer"
            ? await authService.refreshToken(payload)
            : await adminAuthService.refreshToken(payload);
        if (res?.data?.status) {
          setAuthTokenCookie(res.data.data.authToken);
          const originalConfig = error.config;
          originalConfig.baseURL = MAIN_URL;
          return http.request(originalConfig);
        }
      } catch (error) {
        removeAuthTokenCookie();
        toast.error("Your login session has expired. Please log in again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
        window.location.href = "/login";
        return Promise.resolve();
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
