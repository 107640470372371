/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { MdOutlineSend } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { useEffect, useRef, useState } from "react";
import { query, collection, orderBy, onSnapshot, limit, addDoc, serverTimestamp, db, storage } from "../firebase/firebaseConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import downloadSvg from "../assets/images/svg/download.svg";
import { FaRegFileAlt } from "react-icons/fa";
import ProfileImageUpload from "./ProfileImageUpload";
import { doc, updateDoc } from "firebase/firestore";
import Tooltip from "@mui/material/Tooltip";
const AdminChatModule = ({ consumerId = "", name = "", profileImage = null, sendId, recvId, recvEmail }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const chatBoxRef = useRef(null);
  const nameArr = name?.split(" ");
  const initials = `${nameArr[0]?.charAt(0)}${nameArr[1]?.charAt(0)}`?.toUpperCase();
  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };
  const sendMessage = async (event) => {
    event?.preventDefault();
    if (file) {
      const storageRef = ref(storage, `chat_files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log("Uploaded : " + progress + "%");
          // setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addDoc(collection(db, "consumerId_" + consumerId), {
              text_message: "",
              send_by: sendId,
              receive_by: recvId,
              send_time: serverTimestamp(),
              consumer_id: consumerId,
              type: "file",
              media_type: file.type,
              file: downloadURL,
              is_read: false,
              read_time: null,
              //   receiver_email: recvEmail,
            });
          });
        }
      );

      setFile(null);
    } else {
      if (inputMessage.trim() === "") {
        alert("Enter valid message");
        return;
      }
      await addDoc(collection(db, "consumerId_" + consumerId), {
        text_message: inputMessage,
        send_by: sendId,
        receive_by: recvId,
        send_time: serverTimestamp(),
        consumer_id: consumerId,
        is_read: false,
        read_time: null,
        // receiver_email: recvEmail,
      });
      setInputMessage("");
    }
  };

  useEffect(() => {
    const q = query(collection(db, "consumerId_" + consumerId), orderBy("send_time", "asc"), limit(5000));
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      setMessages(fetchedMessages);
      QuerySnapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          const message = change.doc.data();
          if (message.receive_by === sendId && !message?.is_read) {
            const messageRef = doc(db, "consumerId_" + consumerId, change.doc.id);
            updateDoc(messageRef, {
              is_read: true,
              read_time: moment(new Date())?.format("DD/MM/YYYY hh:mm a"),
            }).catch((error) => {
              console.error("Error updating document: ", error);
            });
          }
        }
      });
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (file) {
      sendMessage();
    }
  }, [file]);

  const handleDownload = (fileName) => {
    window.open(fileName, "_blank");
  };
  return (
    <div className="h-[530px] chat">
      <div className="flex flex-col flex-auto h-full">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-green-200 h-full">
          <p className="p-5 py-4 bg-[#DEECD9] rounded-t-2xl xs:text-sm sm:text-base font-medium">
            Chat with <span className="capitalize">{name?.split(" ")[0]}</span>
          </p>
          <div className="flex flex-col h-full overflow-x-auto mb-4 p-4" ref={chatBoxRef}>
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-y-2">
                {messages.map((message, i) =>
                  message.send_by === sendId ? (
                    <div className="col-start-6 col-end-13 px-0 rounded-lg" key={message?.id}>
                      <div className="flex items-end justify-start flex-col">
                        <div className="break-all relative text-sm bg-primary py-2 px-3 shadow rounded-xl text-white flex">
                          {message.type === "file" ? (
                            message?.media_type?.search("image") === -1 ? (
                              <div className="flex space-x-2 items-center">
                                <FaRegFileAlt className="text-white w-4 h-4" />
                                <p className="text-sm font-normal">Document</p>
                              </div>
                            ) : (
                              <div>
                                <img src={message.file} width={150} alt="file" onLoad={scrollToBottom} />
                              </div>
                            )
                          ) : (
                            <div className="text-sm font-normal text-white">{message.text_message}</div>
                          )}
                        </div>
                        {(messages[i + 1]?.send_by !== sendId || i + 1 === messages.length) && (
                          <div className="mt-1 flex space-x-1" style={{ fontSize: "11px", color: "#727272", fontWeight: "500" }}>
                            <p>{message?.send_time?.seconds && moment?.unix(message?.send_time?.seconds)?.format?.("hh:mm A")}</p>
                            <Tooltip title={message?.read_time} disableInteractive>
                              <p>
                                {message?.is_read && "• seen"}
                                {/* <BsCheckAll className="w-5 h-5 text-gray-200 hover:text-primary" /> */}
                              </p>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-start-1 col-end-10 px-0 rounded-lg" key={message?.id}>
                      <div className="flex flex-col">
                        <div className="flex">
                          {profileImage ? (
                            <div className="flex items-center justify-center">
                              <ProfileImageUpload image={profileImage} size={32} isEditable={false} />
                            </div>
                          ) : (
                            <div className="flex items-center justify-center h-8 w-8 rounded-full bg-yellow-100 flex-shrink-0 text-sm font-medium">
                              {initials}
                            </div>
                          )}
                          {message.type === "file" ? (
                            message?.media_type?.search("image") === -1 ? (
                              <div className="flex items-center">
                                <div className="flex space-x-2 bg-white py-2 px-3 shadow rounded-xl ml-3 items-center">
                                  <FaRegFileAlt className="text-primary w-4 h-4" />
                                  <p className="text-sm font-normal">Document</p>
                                </div>
                                <img
                                  className="cursor-pointer"
                                  src={downloadSvg}
                                  width={"28"}
                                  height={"29"}
                                  onClick={() => handleDownload(message.file)}
                                />
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <div className="flex space-x-2 bg-white py-2 px-3 shadow rounded-xl ml-3 items-center">
                                  <img src={message.file} width={150} alt="file" onLoad={scrollToBottom} />
                                </div>
                                <img
                                  className="cursor-pointer"
                                  src={downloadSvg}
                                  width={"28"}
                                  height={"29"}
                                  onClick={() => handleDownload(message.file)}
                                />
                              </div>
                            )
                          ) : (
                            <div className="break-all relative ml-3 text-sm bg-white py-2 px-3 shadow rounded-xl">
                              <div className="text-sm font-normal text-primary">{message.text_message}</div>
                            </div>
                          )}
                        </div>
                        {(messages[i + 1]?.send_by === sendId || i + 1 === messages.length) && (
                          <p className="mt-1" style={{ fontSize: "11px", color: "#727272", marginLeft: "44px", fontWeight: "500" }}>
                            {message?.send_time?.seconds && moment?.unix(message?.send_time?.seconds)?.format?.("hh:mm A")}
                          </p>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row p-4 items-center">
            <div className="flex flex-row items-center xs:h-10 sm:h-12 rounded-full bg-white w-full px-4">
              <div className="flex-grow">
                <div className="relative w-full">
                  <input
                    type="text"
                    placeholder="Enter your message..."
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendMessage(e);
                      }
                    }}
                    className="flex w-full rounded-full focus:outline-none pl-1 pr-12 xs:h-8 sm:h-10 text-sm font-normal"
                  />
                  <button
                    disabled={inputMessage?.trim()?.length === 0}
                    className="absolute flex items-center justify-center h-full right-0 top-0 text-primary"
                    onClick={sendMessage}
                  >
                    <MdOutlineSend className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
            <div className="ml-4">
              <input
                hidden
                type="file"
                id="attachment"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
              <label
                htmlFor="attachment"
                className="cursor-pointer shadow flex items-center justify-center bg-primary hover:bg-white rounded-full text-white hover:text-primary xs:p-2 sm:p-3.5 flex-shrink-0"
              >
                <ImAttachment className="w-5 h-5" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminChatModule;
