import * as Yup from "yup";

const projectTitle = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 characters.")
  .max(100, "Field cannot be more than 100 characters.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const projectType = Yup.object().required("Field is Required.");

const location = Yup.string().required("Field is Required.");

const bidValidity = Yup.string()
  .required("Field is Required.")
  .test("is-number-range", "Field value must be less than 90.", (value) => {
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && numberValue <= 90;
  })
  .test("is-number-range", "Field value must be more than 30.", (value) => {
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && numberValue >= 30;
  });

const agreementType = Yup.object().required("Field is Required.");

const costSharing = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .test("is-number-range", "Field value cannot be more than 100.", (value) => {
    if (!value) return true;
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && numberValue <= 100;
  });
const averageMonthlyPayment = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const leasingTariff = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const capacity = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .max(4, "Field cannot be more than 4 digits.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const tenureLength = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const tenureAge = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const moduleSpecification = Yup.object().required("Field is Required.");

const moduleBrand = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 characters.")
  .max(100, "Field cannot be more than 100 characters.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const gridConnection = Yup.object().required("Field is Required.");

const currentTenancy = Yup.string()
  .required("Field is Required.")
  .min(1, "Field cannot be less than 1 digit.")
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]+$/, "Field cannot contain any special characters.");

const mountingSystem = Yup.string()
  .max(150, "Field cannot be more than 150 characters.")
  .matches(/^[a-zA-Z0-9\s]*$/, "Field cannot contain any special characters.");

const installationDate = Yup.string().required("Field is Required.");

const generationData = Yup.string()
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]*$/, "Field cannot contain any special characters.");

const tempDate = Yup.string().required("Field is Required.");

const askingPrice = Yup.string()
  .required("Field is Required.")
  .max(10, "Field cannot be more than 10 digits.")
  .matches(/^[a-zA-Z0-9\s]*$/, "Field cannot contain any special characters.");

const averageSystemEfficiency = Yup.string().test("is-number-range", "Field value cannot be more than 100.", (value) => {
  if (!value) return true;
  const numberValue = parseFloat(value);
  return !isNaN(numberValue) && numberValue <= 100;
});

const degenerationRate = Yup.string().test("is-number-range", "Field value cannot be more than 100.", (value) => {
  if (!value) return true;
  const numberValue = parseFloat(value);
  return !isNaN(numberValue) && numberValue <= 100;
});

const additionalFeatures = Yup.string().max(1000, "Field cannot be more than 1000 characters.");

const youtubeUrl = Yup.string().matches(
  /^(?:$|(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:&.*)?)$/,
  "Only YouTube URL is accepted here."
);

const projectImages = Yup.number().min(2, "Please select atleast 2 images.");

const extendBidValidity = Yup.string()
  .required("Field is Required.")
  .test("is-number-range", "Field value must be less than 90.", (value) => {
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && numberValue <= 90;
  })
  .test("is-number-range", "Field value must be more than 30.", (value) => {
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && numberValue >= 30;
  });

export const buySellStep1 = Yup.object().shape({ projectTitle, projectType, location, bidValidity });
export const buySellStep2 = Yup.object().shape({
  agreementType,
  capacity,
  gridConnection,
  tenureLength,
  tenureAge,
  moduleSpecification,
  moduleBrand,
  currentTenancy,
  mountingSystem,
  installationDate,
  generationData,
  tempDate,
  askingPrice,
  averageSystemEfficiency,
  degenerationRate,
  additionalFeatures,
});

export const buySellStep2Type1 = buySellStep2.shape({
  costSharing,
  averageMonthlyPayment,
});

export const buySellStep2Type2 = buySellStep2.shape({
  leasingTariff,
});
export const buySellStep3 = Yup.object().shape({ youtubeUrl, projectImages });

export const buySellDetails = Yup.object().shape({ extendBidValidity });
